import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import AttendancePrintComponent from '../attandanceComponent/attendanceprintComponent/AttendancePrintComponent.vue';
import APP_CONST from '@/constants/AppConst';
import UIkit from 'uikit';
import APP_UTILITIES from '@/utilities/commonFunctions';
import attendanceStore from '@/store/modules/attendanceStore';
import {
  getStaffByClassId,
  downloadAttendancePDF,
  resetData,
} from '@/services/attedance/attedanceService';
import NoDataFoundComponent from '@/commoncomponents/nodatafoundcomponent/NoDataFoundComponent.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import printJS from 'print-js';
import './printDoc.css';
import {
  getRoasterData,
  getScholarDetails,
} from '@/services/userService/users-api';
import { getSiteByProgramId } from '@/services/create-user/createUserService';
import Printutility from '@/utilities/PrintComponentMethod';
import PaginationComponent from '../../commoncomponents/paginationcomponent/PaginationComponent.vue';
import AttendanceDetailsSideNav from '../attandanceComponent/attendanceDetailsSideNav/attendanceDetailsSideNav.vue';
import AttendanceDisplayColumns from '../attandanceComponent/attendanceDisplayColumns/attendanceDisplayColumns.vue';
import FixedTableComponent from '../attandanceComponent/fixedTableComponent/FixedTableComponent.vue';
import MovableTableComponent from '../attandanceComponent/movableTableComponent/MovableTableComponent.vue';
import AttendanceFilterColumn from './attendanceDropdownComponent/AttendanceDropdownComponent.vue';
import { ToastType } from '@/Model/toastType';
import {
  AnalyticsInjectionKey,
  AnalyticsService,
  analyticsEventNames,
} from '@/analytics';
import TableTabs from '../TableTabs/TableTabs.vue';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import { Dropdown } from '@/ui-components/multiSelectDropdown/MultiSelectDropdown';
import DatepickerComponent from '@/ui-components/datepicker-v2/DatepickerComponent.vue';
import { dateChangedEvent } from '@/ui-components/datepicker-v2/DatepickerComponent.vue';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import AttendanceSettings from './AttendanceSettings.vue';
import { AttendanceSettingsData, AttendanceViewMode } from '@/Model/attendance';
import SearchBox from '@/commoncomponents/SearchBox/SearchBox.vue';

@Component({
  components: {
    SearchBox,
    'fixed-table-component': FixedTableComponent,
    'movable-table-component': MovableTableComponent,
    'no-data-found': NoDataFoundComponent,
    'bouncing-preloader': BouncingPreloaderComponent,
    pagination: PaginationComponent,
    'attendance-print-component': AttendancePrintComponent,
    'attendance-details-side-nav': AttendanceDetailsSideNav,
    'display-columns-dropdown': AttendanceDisplayColumns,
    'attendace-filters': AttendanceFilterColumn,
    'table-tabs': TableTabs,
    'multi-select-dropdown': MultiSelectDropdown,
    datepicker: DatepickerComponent,
    OffCanvas,
    AttendanceSettings,
  },
})
export default class AttandanceComponent extends Vue {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  readonly STYLE = APP_CONST.STYLE;

  public scholarAttedanceList: any = [];
  public today: any = new Date();
  public dateToSend: any = '';
  public openDd: boolean = false;
  public defaultSelection: boolean = false;
  public currentRowIndex: any = null;
  public currentCellIndex: any = null;
  public currentSortOrder: string = 'asc';
  public classRoomsListDefaultGroup: any = APP_CONST.SCHOLAR_GROUP_DEFAULT;
  public classRoomsListMyGroup: any = [];
  public classRoomsListOtherGroup: any = [];
  public currentWeekSet: any = [];
  public currentSelectedClass: any = {};
  public currentSelectedClasses: any = [];
  public showClassBool: boolean = false;
  public currentClickedDay: string = '';
  public totalScholarCount: number = 0;
  public inputBlur: boolean = false;
  public allHolidays: any = [];
  public backword: boolean = false;
  public forword: boolean = false;
  public theDate = Date.now();
  public scholarIds: any = [];
  public teacherList: any = [];
  public markAttList: any = [];
  public scholarFName: string = '';
  public scholarLName: string = '';
  public currentRemark: string = '';
  private isMobileForAPiCall: boolean = false;
  public uniqueValue: string = '';
  public nameValue: string = '';
  public yourTimeValue: any = { h: '07', mm: '30', A: 'AM' };
  public editTimeColumn: string = '';
  public checkInHeaders: any = APP_CONST.ATTENDANCE_HEADER_DESKTOP.CHECKIN;
  public checkOutHeaders: any = APP_CONST.ATTENDANCE_HEADER_DESKTOP.CHECKOUT;
  public tabs: string[] = ['Check-in', 'Check-out'];
  public attendanceEnhancementsEnabled: boolean = false;
  public groupsDropdown: Dropdown = {
    value: 'All Groups',
    disable: false,
    search: true,
    placeholder: 'All Groups',
    dropdownList: [],
    checked: false,
    isAllSelect: true,
    selectDropdownCount: true,
  };

  public scholarDetails: any = {
    emergencyDetails: {
      phone: '',
      email: '',
    },
    familyDetails: [
      {
        allowedToPickup: '',
        relationship: '',
      },
    ],
    medicalDetails: {},
  };
  private holidaysData: any = {};
  public currentWeekIndex: number = -1;
  public weeks: Array<any> = [];
  public weekDays = ['M', 'T', 'W', 'Th', 'F', 'S', 'Su'];
  public isAttendanceDataLoading: boolean = true;
  public currentClickedDayIndex: any = -1;
  public currentAttendanceDDRef: string = '';
  private scholarAttedanceRef: any = {};
  public currentWeek: any = null;
  public ishowEmergencyDetails: boolean = false;
  index: number | null = null;
  hover: boolean = false;
  hoverText: string = '';
  public siteStartDate: string = '';
  public siteEndDate: string = '';
  private firstNameSorted: boolean = true;
  private lastNameSorted: boolean = false;
  public gradeSorted = false;
  private transportationSorted: boolean = false;
  private currentSortedColumn: string = 'firstName';
  private ascOrder: string = 'asc';
  private descOrder: string = 'desc';
  public isButtonShow: boolean = false;
  private noDataClassScholar = `{ height: 48px; font-family: ${APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT}; font-size: ${APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_200_HEAVY_SIZE}; font-weight: ${APP_CONST.STYLE.FONT_WEIGHT.PARAGRAPH_200_HEAVY_WEIGHT}; font-stretch: normal; font-style: normal; line-height: ${APP_CONST.STYLE.LINE_HEIGHT.PARAGRAPH_200_HEAVY_LINE_HEIGHT}; letter-spacing: normal; text-align: center; color: ${APP_CONST.STYLE.COLOR.BLUE_700}; display: block; width: 524px;}`;
  private no_data_view: boolean = false;
  public mobileView: boolean = false;
  public scIndex: number = 0;
  public attIndex: number = 0;
  public attRecord: any;
  public fotterPopup: boolean = false;
  private siteData: any = [];
  private programName: string = '';
  private siteName: string = '';
  private isPdfPrintInProgress: boolean = false;
  private authKey: string = '';
  public showSeeMoreButton: boolean = false;
  public showSeeLessButton: boolean = false;
  public relationship: string = '';
  private activeGroup: number = 1;
  private showSeeMoreButtonEmerPopup: boolean = true;
  private showSeeLessButtonEmerPopup: boolean = false;
  private authorizedPickup: any = [];
  private authorizedPickupAll: any = [];
  public eleHeightHeader: any = 0;
  public scholarCount: number = 25;
  public page: number = 0;
  public sortField: string = 'firstName';
  public sortDir: number | string = 1;
  public totalActivePagingScholar: number = 0;
  private scholarAttedanceListToDownload: any = [];
  private isGroupChange: boolean = false;
  public startTimeBlurr: boolean = false;
  public currentTime: any = '';
  public currentTimeDay: any = '';
  public updateTime: any = '';
  public errorObj = {
    startTime: {
      isTimeFormat: false,
    },
    endTime: {
      isTimeFormat: false,
    },
  };
  public absentDisabled: boolean = false;
  public currentDissmisedIndex: string = '';
  public staffCheck: boolean = false;
  public absentDayDisabled: boolean = false;
  public dayViewScIndex: number = -1;
  public dayViewDayIndex: number = -1;
  public totalCheckedOutScholarCount: number = 0;
  public tabWeek: any = {};
  paginationReset: boolean = true;
  private currectSelectedRecord: any = {};
  public singleScholarCheckinIndex: string = '';
  public attendenceIds: any = [];
  public statusChangeTimer: any = 0;
  public dayNotesIndex: string = '';
  public dayNotesValue: string = '';
  public showSettings: boolean = false;
  public isDownlodProgress: boolean = false;
  public siteId = 0;
  public programId = 0;
  public endDate: any = '';
  public filteredColumns: { name: string; display: boolean; key?: string }[] = [
    { name: 'Grade', display: true },
    { name: 'Transportation', display: true },
    { name: 'Check-in', display: true },
    { name: 'Time', display: true },
    { name: 'Note', display: true },
  ];
  public mobileOrangeDot: string = '';
  private weekSort: string = '';
  public saveNotesRef: any = [];
  public scholarRecord: any = [];
  public note: string = '';
  public dynamicHeight: any = '';
  public tabSwitch: boolean = false;
  public showSingleClickedDay: any = '';
  public mobileNotesIndex: string = '';
  public mobilePopUpOpen: boolean = false;
  public headersFromStorage: any = [];
  public sortingDropdownStyleObj = { top: '', left: '' };
  public sortingDropdownIndex: string = '';
  public header: any = {};
  public searchField: string = '';
  public searchValue: string = '';
  public weekDay: string = '';
  public currentSearchingDay: string = '';
  public initialTotalItems: number = APP_CONST.ZERO;
  public localConst: any = APP_CONST;
  public isExactMatch: boolean = false;
  public rowsPerActivePage: Record<string, any> = Object.values(this.tabs).reduce((obj, key) => ({
    ...obj,
    [key]: {
      mobile: 10,
      desktop: 25,
    }
  }), {});

  public showAttendanceSettings = false;

  public showGroupsFlyout = false;

  @Prop()
  userDetails!: any;

  @Prop()
  total_items!: 0;

  @Prop()
  isShow!: false;

  @Prop()
  initialPage!: number;

  public attedanceList: any = [
    {
      type: 'Present',
      selectedValue: '',
      attValue: 'P',
      icon: 'present.svg',
    },
    {
      type: 'Absent',
      selectedValue: '',
      attValue: 'A',
      icon: 'absent-icon.svg',
    },
    {
      type: 'Late',
      remark: '',
      selectedValue: '',
      attValue: 'L',
      icon: 'vector.svg',
    },
  ];

  /* istanbul ignore next */
  get tempScholarDisplay() {
    return attendanceStore.tempScholarDisplay;
  }

  /* istanbul ignore next */
  get tempCurrentDisplay() {
    return attendanceStore.tempCurrentDisplay;
  }

  /* istanbul ignore next */
  get checkInTableHeaders() {
    return attendanceStore.checkInHeaders;
  }

  /* istanbul ignore next */
  get checkOutTableHeaders() {
    return attendanceStore.checkOutTableHeaders;
  }

  /* istanbul ignore next */
  get weekTransporation() {
    return attendanceStore.weekTransporation;
  }

  /* istanbul ignore next */
  get checkinGearSettings() {
    return attendanceStore.checkinGearSettings;
  }

  /* istanbul ignore next */
  get transportationFilter() {
    return attendanceStore.isTransportationFilterOpen;
  }

  get gradeFilter() {
    return attendanceStore.isGradeFilterOpen;
  }


  /* istanbul ignore next */
  get search() {
    return attendanceStore.attendanceSearchData;
  }

  get isLoaderVisible() {
    return (
      this.initialTotalItems >= this.localConst.HUNDRED ||
      this.initialTotalItems <= this.localConst.ZERO
    );
  }

  get attendanceViewMode(): AttendanceViewMode {
    return this.tabSelectedValueToAttendanceViewMode(this.tabSelectedValue);
  }

  get currentSelectedWeekLabel(): string {
    let label = '';

    if (!this.currentWeek) {
      return label;
    }

    const firstDate = new Date(this.currentWeek.days[0].date);
    const lastDate = new Date(
      this.currentWeek.days[this.currentWeek.days.length - 1].date
    );
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
    };

    const firstDateLabel = firstDate.toLocaleDateString('en-US', options);
    const lastDateLabel = lastDate.toLocaleDateString('en-US', options);

    const firstYear = firstDate.getFullYear();
    const lastYear = lastDate.getFullYear();

    if (firstYear === lastYear) {
      label = `${firstDateLabel}-${lastDateLabel}, ${firstYear}`;
    }
    else {
      const firstDateWithYearLabel = firstDate.toLocaleDateString('en-US', {
        ...options,
        year: 'numeric',
      });
      const lastDateWithYearLabel = lastDate.toLocaleDateString('en-US', {
        ...options,
        year: 'numeric',
      });
      label = `${firstDateWithYearLabel}-${lastDateWithYearLabel}`;
    }

    return label;
  }

  private objScreenText: ScreenText = new ScreenText();
  public tabSelected: string | null | undefined = 'Check-in';
  public tabSelectedValue: string = 'Day';
  public totalCheckInScholarCount: number = 0;
  public changeIndex: number = -1;

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  setHeight(value: any) {
    this.dynamicHeight = value;
  }

  /* istanbul ignore next */
  handleListTypeSelection(type: string) {
    this.paginationReset = false;
    this.showSettings = false;
    this.isAttendanceDataLoading = true;
    this.initialTotalItems = 0;
    this.currentDissmisedIndex = '';
    if (type == 'Check-in' || type == 'Check-out') {
      this.tabSelected = type;
    }
    else {
      this.tabSelectedValue = type;
    }
    switch (type) {
      case 'Week':
        this.filteredColumns = [
          { name: 'Grade', display: true, key: 'grade' },
          { name: 'Transportation', display: true, key: 'transport' },
          { name: 'Sunday', display: true, key: 'Su' },
          { name: 'Monday', display: true, key: 'M' },
          { name: 'Tuesday', display: true, key: 'T' },
          { name: 'Wednesday', display: true, key: 'W' },
          { name: 'Thursday', display: true, key: 'Th' },
          { name: 'Friday', display: true, key: 'F' },
          { name: 'Saturday', display: true, key: 'S' },
        ];

        if (!this.attendanceEnhancementsEnabled) {
          this.filteredColumns = this.filteredColumns.filter(
            (column) => column.name !== 'Grade'
          );
        }

        if (this.currentSortedColumn == 'Attendance' && !this.weekSort) {
          this.currentSortedColumn = 'firstName';
          this.firstNameSorted = true;
          this.currentSortOrder = 'asc';
          this.setSortingDefault();
        }

        break;
      case 'Check-in':
        this.filteredColumns = [
          { name: 'Grade', display: true, key: 'grade' },
          { name: 'Transportation', display: true },
          { name: 'Check-in', display: true },
          { name: 'Time', display: true },
          { name: 'Note', display: true },
        ];

        if (!this.attendanceEnhancementsEnabled) {
          this.filteredColumns = this.filteredColumns.filter(
            (column) => column.name !== 'Grade'
          );
        }
        else {
          const programId = this.userDetails.programId || APP_UTILITIES.getCookie('programId');
          const siteId = this.userDetails.siteId || APP_UTILITIES.getCookie('siteId');

          const attendanceSettingsMapping = APP_UTILITIES.getLocalStoredAttendanceSettingsMapping();
          const attendanceSettings = attendanceSettingsMapping[`${programId}_${siteId}`];

          if (attendanceSettings) {
            this.tabSelectedValue = this.attendanceViewModeToTabSelectedValue(attendanceSettings.viewMode);
          }
        }

        break;
      case 'Check-out':
        this.tabSelectedValue = 'Day';
        this.filteredColumns = [
          { name: 'Grade', display: true, key: 'grade' },
          { name: 'Transportation', display: true },
          { name: 'Check-out', display: true },
          { name: 'Time', display: true },
          { name: 'Note', display: true },
        ];

        if (!this.attendanceEnhancementsEnabled) {
          this.filteredColumns = this.filteredColumns.filter(
            (column) => column.name !== 'Grade'
          );
        }

        break;
      default:
        this.filteredColumns = [
          { name: 'Grade', display: true, key: 'grade' },
          { name: 'Transportation', display: true },
          { name: 'Check-in', display: true },
          { name: 'Time', display: true },
          { name: 'Note', display: true },
        ];

        if (!this.attendanceEnhancementsEnabled) {
          this.filteredColumns = this.filteredColumns.filter(
            (column) => column.name !== 'Grade'
          );
        }

        this.hideOrangeBar();
        break;
    }
    this.tabSwitch = true;
    //this.showAllHeaders();
    this.getHeadersFromStorage();
    this.getResizeFromStorage();
    this.getSortingAndSearchingDataFromStorage();
    this.page = 1;
    this.showSingleClickedDay = '';
    this.closeSortingDropdown();
    this.emptySearchFields();
    this.prepareInitialDetails();
  }

  returnTeacherData(teacherList: any) {
    const teacherLists = [];
    for (let i = 0; i < teacherList.length; i++) {
      teacherLists.push(this.concateUserName(teacherList[i].teacherDetails));
    }
    setTimeout(() => {
      this.calculateStaffHeight();
    }, 100);
    return teacherLists.toString().replace(/,/g, ', ');
  }

  calculateStaffHeight() {
    const ele = document.getElementById('test2');
    const eleHeight = ele && ele.offsetHeight;
    let height;
    /* istanbul ignore else */
    if (this.mobileView) {
      height = document.documentElement.clientWidth == 768
        ? 55
        : 54;
    }
    else {
      height = 72;
    }
    this.showSeeMoreButton = eleHeight && eleHeight >= height
      ? true
      : false;
  }
  showMore() {
    this.showSeeMoreButton = false;
    this.showSeeLessButton = true;
  }

  showLess() {
    this.showSeeLessButton = false;
    this.showSeeMoreButton = true;
  }

  public formatShortDate(date: any) {
    const convertedDate: any = new Date(date);
    return APP_UTILITIES.formatShortDate(convertedDate);
  }

  private prepareInitialDetails() {
    this.currentWeekIndex = -1;
    this.getWeeksData();
    this.prepareScholarAttendanceData();
  }

  isMobile() {
    if (document.documentElement.clientWidth < 769 && !this.mobileView) {
      this.mobileView = true;
      this.scholarCount = 10;
      this.prepareInitialDetails();
      this.closeEmergencyModal();
      this.closesectionModal();
    }
    else if (document.documentElement.clientWidth >= 769 && this.mobileView) {
      this.scholarCount = 25;
      this.closeEmergencyModal();
      this.mobileView = false;
      this.prepareInitialDetails();
      this.closesectionModal();
    }
  }

  mounted() {
    if (APP_UTILITIES.coreids().roleId == 7) {
      this.staffCheck = true;
    }
    else {
      this.staffCheck = false;
    }
    this.getHeadersFromStorage();
    Vue.prototype.$eventHub.$on('undoChanges', this.undoChanges);
    this.mobileView =
      document.documentElement.clientWidth <= 768
        ? true
        : false;
    window.addEventListener('resize', this.checkResizeAndOrientation);
    window.addEventListener('orientation', this.checkResizeAndOrientation);
    window.addEventListener('resize', APP_UTILITIES.debounce(this.isMobile));
    this.calculateHeaderHeight();
    //this.applySortingWithAuth();
  }

  /* istanbul ignore next */
  async beforeMount() {
    await APP_UTILITIES.waitForLDClient();
    this.attendanceEnhancementsEnabled = await APP_UTILITIES.getFeatureFlag(
      APP_CONST.FEATURE_KEYS.attendanceEnhancements
    );
    this.getResizeFromStorage();
    this.getSortingAndSearchingDataFromStorage();

    if (!this.attendanceEnhancementsEnabled) {
      this.filteredColumns = this.filteredColumns.filter(
        (column) => column.name !== 'Grade'
      );

      this.checkInHeaders = APP_CONST.LEGACY_ATTENDANCE_HEADER_DESKTOP.CHECKIN;
      this.checkOutHeaders = APP_CONST.LEGACY_ATTENDANCE_HEADER_DESKTOP.CHECKOUT;

      attendanceStore.mutateCheckinHeaders(this.checkInHeaders);
      attendanceStore.mutateCheckOutHeaders(this.checkOutHeaders);
    }
    else {
      const programId = this.userDetails.programId || APP_UTILITIES.getCookie('programId');
      const siteId = this.userDetails.siteId || APP_UTILITIES.getCookie('siteId');

      const attendanceSettingsMapping = APP_UTILITIES.getLocalStoredAttendanceSettingsMapping();
      const attendanceSettings = attendanceSettingsMapping[`${programId}_${siteId}`];

      if (attendanceSettings) {
        this.setAttendanceSettings(attendanceSettings);
      }
    }

    /* Setting rows per page persistence */
    if (!localStorage.getItem(APP_CONST.LOCAL_STORAGE_KEYS.attendance.rowsPerPage)) {
      localStorage.setItem(APP_CONST.LOCAL_STORAGE_KEYS.attendance.rowsPerPage, JSON.stringify(this.rowsPerActivePage));
    }
    else {
      this.rowsPerActivePage = JSON.parse(localStorage.getItem(APP_CONST.LOCAL_STORAGE_KEYS.attendance.rowsPerPage) as string);
    }
  }

  /* istanbul ignore next */
  destroyed() {
    window.removeEventListener('resize', this.isMobile);
    Vue.prototype.$eventHub.$off('undoChanges', this.undoChanges);
    clearTimeout(this.statusChangeTimer);
    this.showAllHeaders();
    if (this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day') {
      attendanceStore.mutateCheckInResizeObj([]);
    }
  }

  hideOrangeBar() {
    if (this.statusChangeTimer > 0) {
      clearTimeout(this.statusChangeTimer);
      this.statusChangeTimer = 0;
      Vue.prototype.$eventHub.$emit('showAttendanceUndoStripIndicater', {
        component: '',
        stripShow: false,
        attendanceStripShow: false,
      });
    }
  }

  undoChanges() {
    this.hideOrangeBar();
    const undoData = {
      attendenceIds: this.attendenceIds,
      programId:
        this.userDetails.programId === 0
          ? APP_UTILITIES.getCookie('programId')
          : this.userDetails.programId,
      siteId:
        this.userDetails.siteId === 0
          ? APP_UTILITIES.getCookie('siteId')
          : this.userDetails.siteId,
    };
    attendanceStore.undoAttendance(undoData).then((res: any) => {
      if (res.status === APP_CONST.RESPONSE_200) {
        APP_UTILITIES.showToastMessage('Action undone.', ToastType.Success);
        this.prepareScholarAttendanceData();
      }
    });
  }

  calculateHeaderHeight() {
    const header = document.getElementById('header-wrapper') as HTMLElement;
    const indication = document.getElementById(
      'indication-strip'
    ) as HTMLElement;
    const drillDownBar = document.getElementById(
      'indication-strip-drill'
    ) as HTMLElement;
    const super_user_drilldown = APP_UTILITIES.getCookie('super_user_drilldown');
    const eleHeight = header && header.offsetHeight;
    const stripheight = indication && indication.offsetHeight;
    const drillStripheight = drillDownBar && drillDownBar.offsetHeight;
    if (this.eleHeightHeader != null) {
      this.eleHeightHeader = APP_UTILITIES.isMobile()
        ? (this.eleHeightHeader = stripheight)
        : super_user_drilldown
          ? (this.eleHeightHeader = eleHeight + drillStripheight)
          : (this.eleHeightHeader = eleHeight + stripheight);
    }
  }

  public async created() {
    this.isAttendanceDataLoading = true;
    const { accountId } = APP_UTILITIES.coreids();
    const scholarStepObj = {
      siteId:
        this.userDetails.siteId === 0
          ? APP_UTILITIES.getCookie('siteId')
          : this.userDetails.siteId,
      stepNumber: 5,
      programId:
        this.userDetails.programId === 0
          ? APP_UTILITIES.getCookie('programId')
          : this.userDetails.programId,
      accountId: accountId,
    };
    this.isMobileForAPiCall = APP_UTILITIES.mobileAndTabletCheck();
    getRoasterData(scholarStepObj)
      .then((response: any) => {
        /* istanbul ignore else */
        if (response.status == 204) {
          this.no_data_view = true;
        }
      })
      .catch((err: any) => {
        this.no_data_view = false;
      });

    const args = {
      includeAll: true,
      programId:
        this.userDetails.programId === 0
          ? APP_UTILITIES.getCookie('programId')
          : this.userDetails.programId,
      siteId:
        this.userDetails.siteId === 0
          ? APP_UTILITIES.getCookie('siteId')
          : this.userDetails.siteId,
    };
    const progId =
      this.userDetails.programId === 0
        ? APP_UTILITIES.getCookie('programId')
        : this.userDetails.programId;
    const siteResponse = await getSiteByProgramId(progId);
    /* istanbul ignore else */
    if (siteResponse && siteResponse.data) {
      this.siteData = siteResponse.data;
      siteResponse.data.forEach((item: any) => {
        if (args.siteId === item.siteId) {
          this.siteStartDate = item.startDate;
          this.siteEndDate = item.endDate;
        }
      });
    }
    attendanceStore
      .getAllHolidays({
        siteId: this.userDetails.siteId,
        check: false,
        programId: progId,
      })
      .then((holidays: any) => {
        /* istanbul ignore else */
        if (
          holidays.status === APP_CONST.RESPONSE_200 ||
          holidays.status === APP_CONST.RESPONSE_204
        ) {
          this.allHolidays = holidays.data || [];

          holidays.data.length > 0 &&
            holidays.data.forEach((holiday: any, index: number) => {
              holiday.startDate = this.makeDateString(holiday.startDate);
              holiday.endDate = this.makeDateString(holiday.endDate);
              this.holidaysData[
                'holiday_' + new Date(holiday.startDate).getTime()
              ] = holiday;
            });
          this.getWeeksData();
          this.currentSelectedClass = this.classRoomsListDefaultGroup[0];
          this.prepareScholarAttendanceData();
          attendanceStore.getClassRooms(args).then((classList: any) => {
            this.getClassRoomRes(classList);
          });
        }
      });

    this.programName = APP_UTILITIES.getCookie('progName') || '';
    this.siteName = APP_UTILITIES.getCookie('siteName') || '';
    this.siteName = this.siteName.replace(/^"|"$/g, '');
  }

  getClassRoomRes(classList: any) {
    /* istanbul ignore else */
    if (
      classList &&
      classList.data &&
      classList.status === APP_CONST.RESPONSE_200
    ) {
      const classRoomsList = classList.data;
      if (classRoomsList && classRoomsList.length == 0) {
        this.scholarAttedanceList = [];
      }
      else {
        classRoomsList.sort(this.compareValues('classroomName', 'asc'));
        for (const classRoom of classRoomsList) {
          if (this.attendanceEnhancementsEnabled) {
            this.groupsDropdown.dropdownList.push({
              value: classRoom.classroomName,
              checked: false,
              ...classRoom,
            });
          }
          else if (classRoom.myGroup == true) {
            this.classRoomsListMyGroup.push(classRoom);
          }
          else {
            this.classRoomsListOtherGroup.push(classRoom);
          }
        }
      }
    }
    else {
      this.scholarAttedanceList = [];
      const week = this.weeks[this.currentWeekIndex];
      this.currentWeek = week;
    }
  }

  beforeCreate() {
    this.isMobileForAPiCall = APP_UTILITIES.mobileAndTabletCheck();
  }
  private async getTeacherList(currentSelectedClass: any) {
    const teacherListData = await getStaffByClassId(currentSelectedClass.id);
    /* istanbul ignore else */
    if (teacherListData.status === APP_CONST.RESPONSE_200) {
      this.teacherList = teacherListData.data;
    }
    else {
      this.teacherList = [];
    }
  }

  private getWeeksData() {
    this.weeks = [];
    const dayInitials = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];
    const siteId =
      this.userDetails.siteId === 0
        ? APP_UTILITIES.getCookie('siteId')
        : this.userDetails.siteId;
    const filteredSiteArray = this.siteData.filter(function currentSite(
      site: any
    ) {
      return site.siteId == siteId;
    });
    let getSiteActiveDays =
      (filteredSiteArray[0] && filteredSiteArray[0].selectedDays) || '';
    getSiteActiveDays = getSiteActiveDays.split(',');
    let weekStartDate;
    let weekEndDate;
    const pStartDate = new Date(this.makeDateString(this.siteStartDate));
    const pEndDate = new Date(this.makeDateString(this.siteEndDate));

    let day = new Date(pStartDate);

    const today = new Date(this.makeDateString(new Date(), false));

    let days = [];

    let dIndex = day.getDay();
    if (!this.mobileView && dIndex > 0) {
      const dTempIndex = dIndex;
      day = new Date(day.setDate(day.getDate() - dIndex));
      dIndex = day.getDay();
      while (dIndex < dTempIndex) {
        days.push({
          dayIndex: dIndex,
          dayType: 'NA',
          day: dayInitials[dIndex],
          date: day.toString(),
          attendance: 'NA',
          isHoliday: false,
          isHolidayVisible: false,
          holidays: {},
        });
        day = new Date(day.setDate(day.getDate() + 1));
        dIndex = day.getDay();
      }
    }
    day = new Date(pStartDate);
    while (day <= pEndDate) {
      dIndex = day.getDay();
      const dayDataObj = {
        dayIndex: dIndex,
        day: dayInitials[dIndex],
        date: day.toString(),
        dayType: this.holidaysData['holiday_' + new Date(day).getTime()]
          ? 'H'
          : 'A',
        attendance: '',
        isHolidayVisible: false,
        isHoliday: this.holidaysData['holiday_' + new Date(day).getTime()]
          ? true
          : false,
        holidays: this.holidaysData['holiday_' + new Date(day).getTime()]
          ? this.holidaysData['holiday_' + new Date(day).getTime()]
          : {},
      };

      if (
        day.getTime() > today.getTime() ||
        getSiteActiveDays.indexOf(dayInitials[day.getDay()]) == -1
      ) {
        dayDataObj.attendance = 'NA';
        /* istanbul ignore else */
        if (day.getTime() <= today.getTime() && dayDataObj.dayType != 'H') {
          dayDataObj.isHoliday = true;
          dayDataObj.holidays.name = 'Program Closed';
        }
      }
      days.push(dayDataObj);
      /* istanbul ignore if */
      if (
        !this.mobileView &&
        day.getTime() == pEndDate.getTime() &&
        dIndex < 6 &&
        this.tabSelectedValue != 'Day'
      ) {
        while (dIndex != 6) {
          day = new Date(day.setDate(day.getDate() + 1));
          dIndex = day.getDay();
          days.push({
            dayIndex: dIndex,
            dayType: 'NA',
            day: dayInitials[dIndex],
            date: day.toString(),
            attendance: 'NA',
            isHoliday: false,
            isHolidayVisible: false,
            holidays: {},
          });
        }
      }
      /* istanbul ignore if */
      if (
        this.tabSelectedValue == 'Day' ||
        this.mobileView ||
        (!this.mobileView && dIndex == 6)
      ) {
        if (days.length > 1 && this.tabSelectedValue == 'Day') {
          weekStartDate = this.makeDateString(days[days.length - 1].date);
          const dayViewDay = days[days.length - 1];
          days = [];
          days.push({
            dayIndex: dayViewDay.dayIndex,
            dayType: dayViewDay.dayType,
            day: dayViewDay.day,
            date: dayViewDay.date,
            attendance: dayViewDay.attendance,
            isHoliday: dayViewDay.isHoliday,
            holidays:
              dayViewDay.holidays != undefined
                ? dayViewDay.holidays
                : {},
            isHolidayVisible: dayViewDay.isHolidayVisible,
            isOpen: false,
          });
        }
        else {
          weekStartDate = this.makeDateString(days[0].date, false);
        }
        weekEndDate = this.makeDateString(days[days.length - 1].date, false);
        const week = {
          index: this.weeks.length,
          days: days,
          weekStartDate: weekStartDate,
          weekEndDate: weekEndDate,
          attendanceRangeText: this.getAttendanceRangeText(
            weekStartDate,
            weekEndDate
          ),
        };

        const calendarWeekEndDate = new Date(week.weekEndDate).setDate(
          new Date(week.weekEndDate).getDate() + 2
        );

        /* istanbul ignore if */
        if (
          today.getTime() >= new Date(week.weekStartDate).getTime() &&
          today.getTime() <= new Date(calendarWeekEndDate).getTime()
        ) {
          this.currentWeekIndex = this.weeks.length;
        }

        this.weeks.push(week);
        days = [];
      }

      day = new Date(day.setDate(day.getDate() + 1));
    }
    /* istanbul ignore else */
    if (this.currentWeekIndex == -1) {
      this.currentWeekIndex = 0;
      /* istanbul ignore else */
      if (today.getTime() > pEndDate.getTime()) {
        this.currentWeekIndex = this.weeks.length - 1;
      }
    }
  }

  public prepareScholarAttendanceData(fromDownload?: boolean) {
    let week: any = {};

    // Add guard clause to prevent accessing undefined week
    if (!this.weeks || this.weeks.length === 0) {
      return;
    }

    if (this.changeIndex != -1 && this.tabSelectedValue == "Day") {
      week = this.weeks[this.changeIndex];
      this.currentWeekIndex = this.changeIndex;
    }
    else {
      week = this.weeks[this.currentWeekIndex];
      this.changeIndex = -1;
    }
    if (this.tabSelectedValue == 'Week') {
      this.changeIndex = -1;
    }
    const scholarAttedanceRef: any = {};
    const sortOrder: number = this.currentSortOrder == 'desc'
      ? 2
      : 1;
    let attedanceArgs = {};
    if (fromDownload) {
      this.scholarAttedanceListToDownload = [];

      attedanceArgs = {
        classId: this.currentSelectedClass.id,
        siteId:
          this.userDetails.siteId === 0
            ? APP_UTILITIES.getCookie('siteId')
            : this.userDetails.siteId,
        programId:
          this.userDetails.programId === 0
            ? APP_UTILITIES.getCookie('programId')
            : this.userDetails.programId,
        startDate: week.weekStartDate,
        endDate: week.weekEndDate,
        group: this.activeGroup,
        count: this.totalActivePagingScholar,
        page: 1,
        sortField: this.currentSortedColumn,
        sortDir: sortOrder,
        type: this.tabSelected == 'Check-in'
          ? 1
          : 2,
        weekDay:
          this.tabSelected == 'Check-in' && this.tabSelectedValue != 'Day'
            ? this.weekDay
            : '',
        SearchField: this.searchField
          ? this.searchField
          : '',
        Search: this.searchValue
          ? this.searchValue
          : '',
        isExactMatch: this.isExactMatch
          ? this.isExactMatch
          : false,
      };
    }
    else {
      this.currentAttendanceDDRef = '';
      this.currentClickedDayIndex = -1;
      // this.isAttendanceDataLoading = true;
      this.currentWeek = week;
      this.totalScholarCount = 0;
      this.currentClickedDay = '';
      this.singleScholarCheckinIndex = '';
      this.mobileOrangeDot = '';

      attedanceArgs = {
        classId: this.currentSelectedClass.id,
        classIds: this.currentSelectedClasses.map((x: any) => x.id),
        siteId:
          this.userDetails.siteId === 0
            ? APP_UTILITIES.getCookie('siteId')
            : this.userDetails.siteId,
        programId:
          this.userDetails.programId === 0
            ? APP_UTILITIES.getCookie('programId')
            : this.userDetails.programId,
        startDate: week.weekStartDate,
        endDate: week.weekEndDate,
        group: this.activeGroup
          ? this.activeGroup
          : 1,
        count: this.rowsPerPage,
        page: this.page || 1,
        sortField: this.currentSortedColumn,
        sortDir: sortOrder,
        type: this.tabSelected == 'Check-in'
          ? 1
          : 2,
        weekDay:
          this.tabSelected == 'Check-in' && this.tabSelectedValue != 'Day'
            ? this.weekDay
            : '',
        SearchField: this.searchField
          ? this.searchField
          : '',
        Search: this.searchValue
          ? this.searchValue
          : '',
        isExactMatch: this.isExactMatch
          ? this.isExactMatch
          : false,
      };
    }
    attendanceStore.mutateAttendancePayload(attedanceArgs);
    attendanceStore
      .getScholarAttedance(attedanceArgs)
      .then((scholarAttedance: any) => {
        /* istanbul ignore else */
        if (
          scholarAttedance &&
          scholarAttedance.status === APP_CONST.RESPONSE_200
        ) {
          this.paginationReset = true;
          this.no_data_view = false;
          const scholars = scholarAttedance.data.scholars;
          this.totalCheckInScholarCount =
            scholarAttedance.data.totalCheckInScholarCount;
          this.totalCheckedOutScholarCount =
            scholarAttedance.data.totalCheckOutScholarCount;
          if (
            new Date(
              this.makeDateString(
                this.weeks[this.currentWeekIndex].weekStartDate
              )
            ).getTime() ==
            new Date(
              this.makeDateString(scholarAttedance.data.startDate)
            ).getTime()
          ) {
            if (!fromDownload) {
              this.totalScholarCount = scholarAttedance.data.scholars.length;
              this.totalActivePagingScholar =
                scholarAttedance.data.totalScholarCount;
            }

            const scholarAttedanceList: Array<any> = [];
            scholars.forEach((scholar: any, scIndex: number) => {
              const scholarItem: any = JSON.parse(JSON.stringify(scholar));
              const daysInitialsArr = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];
              let dayIndexTemp = 0;
              scholarItem.attendance = [];
              scholarItem.lastInitial = scholarItem.lastName.charAt(0) + '.';
              week.days.forEach((day: any, dayIndex: number) => {
                const array = scholarItem.dayInfo.activeDays;
                let objAttendanceData: any = {
                  isHoliday: day.isHoliday,
                  remarks: '',
                  status: false,
                  isMarked: false,
                  attendanceDate: this.makeDateString(day.date, false),
                  isScheduled:
                    array.indexOf(day.day) !== -1 ||
                    new Date(this.makeDateString(day.date, false)).getTime()
                      > new Date().getTime()
                      ? true
                      : false,
                  attendance: day.attendance,
                };

                /* istanbul ignore else */
                if (scholar.hasOwnProperty('enrollmentDate')) {
                  /* istanbul ignore else */
                  if (
                    new Date(scholar.enrollmentDate).getTime()
                    > new Date(this.makeDateString(day.date, false)).getTime()
                  ) {
                    objAttendanceData.attendance = 'NA';
                    objAttendanceData.isScheduled = true;
                  }
                }
                /* istanbul ignore else */
                if (scholar.hasOwnProperty('inactiveDate')) {
                  /* istanbul ignore else */
                  if (
                    new Date(scholar.inactiveDate).getTime()
                    <= new Date(this.makeDateString(day.date, false)).getTime()
                  ) {
                    objAttendanceData.attendance = 'NA';
                    objAttendanceData.isScheduled = true;
                  }
                }

                const pStartDate = new Date(
                  this.makeDateString(this.siteStartDate)
                );
                const pEndDate = new Date(this.makeDateString(this.siteEndDate));
                const selDate = new Date(this.makeDateString(day.date, false));

                if (pStartDate > selDate || pEndDate < selDate) {
                  objAttendanceData.isScheduled = true;
                }

                const ref = 'item_' + scholar.id + '_' + dayIndex;
                objAttendanceData.ref = ref;
                /* istanbul ignore else */
                if (scholar.attendance && scholar.attendance.length > 0) {
                  scholar.attendance.forEach(
                    (attendanceItem: any, attIndex: number) => {
                      if (
                        this.tabSelectedValue == 'Day' ||
                        this.tabSelectedValue == 'Week'
                      ) {
                        if (attendanceItem.checkInTime) {
                          const data = APP_UTILITIES.formatAMPM(
                            attendanceItem.checkInTime
                          );
                          attendanceItem.checkInTime =
                            attendanceItem.checkInTime.length > 10
                              ? APP_UTILITIES.formatAMPM(
                                attendanceItem.checkInTime
                              )
                              : attendanceItem.checkInTime;
                        }

                        /* istanbul ignore else */
                        if (attendanceItem.checkOutTime) {
                          attendanceItem.checkOutTime =
                            APP_UTILITIES.formatAMPM(
                              attendanceItem.checkOutTime
                            );
                        }
                      }

                      /* istanbul ignore else */
                      if (
                        new Date(
                          this.makeDateString(
                            attendanceItem.attendanceDate,
                            false
                          )
                        ).getTime() ==
                        new Date(this.makeDateString(day.date, false)).getTime()
                      ) {
                        objAttendanceData = { ...attendanceItem };
                        objAttendanceData.isMarked =
                          attendanceItem.attendance === 'P' ||
                          attendanceItem.attendance === 'A' ||
                          attendanceItem.attendance === 'L'
                            ? true
                            : false;
                      }
                    }
                  );
                }
                const holidayData =
                  this.holidaysData['holiday_' + new Date(day.date).getTime()];
                /* istanbul ignore if */
                if (holidayData) {
                  objAttendanceData.isHoliday = true;
                  objAttendanceData.holidays = holidayData;
                  objAttendanceData.attendance = 'NA';
                }

                /* istanbul ignore else */
                if (
                  scholar.hasOwnProperty('dayInfo') &&
                  scholar.dayInfo.hasOwnProperty('activeDays') &&
                  scholar.dayInfo.activeDays.length > 0
                ) {
                  if (
                    (daysInitialsArr[dayIndex] ==
                      scholar.dayInfo.activeDays[dayIndexTemp] &&
                      week.days.length > 1) ||
                    (week.days.length == 1 &&
                      scholar.dayInfo.activeDays.indexOf(day.day) != -1)
                  ) {
                    dayIndexTemp = dayIndexTemp + 1;
                  }
                  else {
                    objAttendanceData.attendance = 'NA';
                  }
                }
                else {
                  objAttendanceData.attendance = 'NA';
                }
                scholarItem.attendance.push(objAttendanceData);

                scholarAttedanceRef[ref] = objAttendanceData;
              });

              scholarAttedanceList.push(scholarItem);
            });
            if (!fromDownload) {
              this.scholarAttedanceList = scholarAttedanceList;
              this.totalScholarCount = scholarAttedanceList.length;
              this.scholarAttedanceRef = scholarAttedanceRef;
              this.mapCheckInHeadersFromStorage(this.headersFromStorage);
              //this.mapDisplayValues();
              setTimeout(() => {
                this.isGroupChange = false;
                this.isAttendanceDataLoading = false;
                this.scholarAttedanceList.sort(
                  this.compareValues(
                    this.currentSortedColumn,
                    this.currentSortOrder
                  )
                );
              }, 0);
            }
            else {
              this.scholarAttedanceListToDownload =
                scholarAttedanceList.slice(0);
              setTimeout(() => {
                this.makePDF();
              }, 0);
            }
          }
          if (this.initialTotalItems == APP_CONST.ZERO) {
            this.initialTotalItems = this.totalActivePagingScholar;
          }
          const movaableTable = this.$refs && (this.$refs.movaableTable as any);
          if (movaableTable) {
            setTimeout(() => {
              this.getResizeFromStorage();
              movaableTable.resizableTable();
              movaableTable.updateTableView();
              movaableTable.updateScrollBar();
            }, 50);
          }
        }
        else if (
          scholarAttedance &&
          scholarAttedance.status === APP_CONST.RESPONSE_204
        ) {
          this.scholarAttedanceList = [];
          this.mapCheckInHeadersFromStorage(this.headersFromStorage);
          this.hideLoaderOnCallFail(fromDownload);
        }
        else {
          this.hideLoaderOnCallFail(fromDownload);
        }
      });
  }

  hideLoaderOnCallFail(fromDownload?: boolean) {
    if (fromDownload) {
      this.isPdfPrintInProgress = false;
    }
    else {
      this.isAttendanceDataLoading = false;
    }
  }

  showHoliday(day: any, index: number) {
    this.currentWeek.days[index].isHolidayVisible = true;
  }

  hideHoliday() {
    this.currentWeek.days.forEach((item: any, index: number) => {
      item.isHolidayVisible = false;
    });
  }

  public async setCurrentClass(
    classObj: any,
    classIndex: number,
    group: number
  ) {
    if (this.attendanceEnhancementsEnabled) {
      const isAllSelect = classObj.isAllSelect;
      classObj = classObj.selectedValue;
      classIndex = 0;
      group = 3;

      // Add or remove the selected class from the current selected classes list
      if (classObj) {
        if (classObj.checked) {
          if (!this.currentSelectedClasses.find((x: any) => x.id === classObj.id)) {
            this.currentSelectedClasses.push(classObj);
          }
        }
        else {
          const index = this.currentSelectedClasses.findIndex((x: any) => x.id === classObj.id);
          if (index !== -1) {
            this.currentSelectedClasses.splice(index, 1);
          }
        }
      }

      if (isAllSelect) {
        if (Array.isArray(classObj)) {
          this.currentSelectedClasses = classObj.filter((x: any) => x.checked);
        }
        classObj = {};
        classObj.classroomName = 'All Groups';
        classObj.groupVal = 1;
        classObj.id = 0;
        group = 1;
      }
    }

    this.isGroupChange = true;
    /* istanbul ignore else */
    if (
      classObj &&
      ((this.currentSelectedClass.classroomName == classObj.classroomName &&
        this.activeGroup != group) ||
        this.currentSelectedClass.classroomName != classObj.classroomName) ||
      this.currentSelectedClasses.length > 0
    ) {
      this.teacherList = [];
      this.isGroupChange = true;
      this.showSeeMoreButton = false;
      this.showSeeLessButton = false;
      this.totalActivePagingScholar = 0;
      this.totalCheckInScholarCount = 0;
      this.totalCheckedOutScholarCount = 0;
      this.currentSelectedClass = classObj;
      this.showClassBool = false;
      this.activeGroup = group;
      this.page = 1;
      // Ensure weeks are initialized before calling prepareScholarAttendanceData
      if (!this.weeks || this.weeks.length === 0) {
        await this.getWeeksData();
      }

      this.prepareScholarAttendanceData();

      /* istanbul ignore else */
      if (group == 3) {
        this.getTeacherList(this.currentSelectedClass);
      }
    }
    else {
      this.isGroupChange = false;
    }
  }

  /* istanbul ignore next */
  private getAttendanceRangeText(startDate: any, endDate: any) {
    let attendanceRangeText: string = '';

    if (startDate && endDate) {
      startDate = new Date(startDate);
      endDate = new Date(endDate);

      let firstPart = '';
      let secondPart = '';
      if (this.mobileView || this.tabSelectedValue == 'Day') {
        firstPart =
          APP_UTILITIES.getFullDayName(startDate.toString()) +
          ', ' +
          APP_UTILITIES.fullMonthFormat(startDate.toString()) +
          ' ' +
          startDate.getDate() +
          ', ' +
          startDate.getFullYear();
        attendanceRangeText = firstPart;
      }
      else if (startDate.getFullYear() != endDate.getFullYear()) {
        firstPart =
          APP_UTILITIES.fullMonthFormat(startDate.toString()) +
          ' ' +
          startDate.getDate() +
          ', ' +
          startDate.getFullYear();
        secondPart =
          APP_UTILITIES.fullMonthFormat(endDate.toString()) +
          ' ' +
          endDate.getDate() +
          ', ' +
          endDate.getFullYear();
        attendanceRangeText = firstPart + '-' + secondPart;
      }
      else if (startDate.getMonth() != endDate.getMonth()) {
        firstPart =
          APP_UTILITIES.fullMonthFormat(startDate.toString()) +
          ' ' +
          startDate.getDate();
        secondPart =
          APP_UTILITIES.fullMonthFormat(endDate.toString()) +
          ' ' +
          endDate.getDate() +
          ', ' +
          endDate.getFullYear();
        attendanceRangeText = firstPart + '-' + secondPart;
      }
      else {
        firstPart =
          APP_UTILITIES.fullMonthFormat(startDate.toString()) +
          ' ' +
          startDate.getDate();
        secondPart = endDate.getDate() + ', ' + endDate.getFullYear();
        attendanceRangeText = firstPart + '-' + secondPart;
      }
    }
    return attendanceRangeText;
  }

  /**
   * Converts a date to a string format.
   * @param date The input date.
   * @param useUTC Determines whether to use UTC methods or local time methods.
   * @returns A string representing the date in MM/DD/YYYY format.
   */
  public makeDateString(date: any, useUTC: boolean = true): string {
    date = new Date(date);
    const day = useUTC
      ? date.getUTCDate()
      : date.getDate();
    const month = useUTC
      ? date.getUTCMonth() + 1
      : date.getMonth() + 1;
    const year = useUTC
      ? date.getUTCFullYear()
      : date.getFullYear();
    return `${String(month).padStart(2, '0')}/${String(day).padStart(
      2,
      '0'
    )}/${year}`;
  }

  public datePickerUpdated(update: dateChangedEvent) {
    const oneDay = 24 * 60 * 60 * 1000;
    const oldDate = new Date(this.currentWeek.days[0].date);
    const diffDays = Math.round(
      Math.abs((update.newDate.getTime() - oldDate.getTime()) / oneDay)
    );

    if (diffDays === 0) {
      return;
    }

    if (update.newDate > oldDate) {
      this.forward(diffDays);
      return;
    }

    this.backward(diffDays);
  }

  public backward(customWeekIndex: number = 1) {
    this.paginationReset = false;
    this.currentWeekIndex = this.currentWeekIndex - customWeekIndex;
    if (this.tabSelectedValue == 'Day') {
      this.changeIndex = this.currentWeekIndex;
      this.totalCheckInScholarCount = 0;
    }
    this.getHeadersFromStorage();
    this.getResizeFromStorage();
    this.page = 1;
    this.closeSortingDropdown();
    this.prepareScholarAttendanceData();
    this.currentDissmisedIndex = '';
    this.tabSwitch = false;
    this.showSingleClickedDay = '';
  }

  public forward(customWeekIndex: number = 1) {
    this.paginationReset = false;
    this.currentWeekIndex = this.currentWeekIndex + customWeekIndex;
    if (this.tabSelectedValue == 'Day') {
      this.changeIndex = this.currentWeekIndex;
      this.totalCheckInScholarCount = 0;
    }
    this.getHeadersFromStorage();
    this.getResizeFromStorage();
    this.page = 1;
    this.closeSortingDropdown();
    this.prepareScholarAttendanceData();
    this.currentDissmisedIndex = '';
    this.tabSwitch = false;
    this.showSingleClickedDay = '';
  }

  /* istanbul ignore next */
  public sortHeaders(field: string, dayItem?: any) {
    if (field == 'firstName') {
      if (this.currentSortedColumn != 'firstName') {
        this.currentSortOrder = 'desc';
      }
      this.firstNameSorted = true;
      this.lastNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.currentSortedColumn = 'firstName';
    }
    else if (field == 'lastName') {
      if (this.currentSortedColumn != 'lastName') {
        this.currentSortOrder = 'desc';
      }
      this.lastNameSorted = true;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.currentSortedColumn = 'lastName';
    }
    else if (field === 'grade') {
      if (this.currentSortedColumn !== 'grade') {
        this.currentSortOrder = 'desc';
      }
      this.gradeSorted = true;
      this.firstNameSorted = false;
      this.lastNameSorted = false;
      this.transportationSorted = false;
      this.currentSortedColumn = 'grade';
    }
    else if (field == 'transportation') {
      if (
        this.currentSortedColumn != 'arrivalPlan' &&
        this.currentSortedColumn != 'dismissalPlan'
      ) {
        this.currentSortOrder = 'desc';
      }
      this.gradeSorted = false;
      this.transportationSorted = true;
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.currentSortedColumn =
        this.tabSelected === 'Check-in'
          ? 'arrivalPlan'
          : 'dismissalPlan';
    }
    else if (this.tabSelectedValue == 'Day' && field == 'Attendance') {
      if (
        dayItem &&
        (dayItem.isHoliday ||
          dayItem.dayType == 'H' ||
          dayItem.dayType == 'NA' ||
          dayItem.attendance == 'NA')
      ) {
        return;
      }
      if (this.currentSortedColumn != 'Attendance') {
        this.currentSortOrder = 'desc';
      }
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.currentSortedColumn = field;
    }
    else {
      if (
        dayItem &&
        (dayItem.isHoliday ||
          dayItem.dayType == 'H' ||
          dayItem.dayType == 'NA' ||
          dayItem.attendance == 'NA')
      ) {
        return;
      }
      if (
        this.currentSortedColumn != 'Attendance' &&
        this.tabSelectedValue !== 'Day'
      ) {
        this.currentSortOrder = 'desc';
      }
      this.weekSort = dayItem && dayItem.day
        ? dayItem.day
        : '';
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.currentSortedColumn = field;
    }

    if (this.currentSortOrder == '') {
      this.currentSortOrder = 'asc';
    }
    else if (this.currentSortOrder === 'asc') {
      this.currentSortOrder = 'desc';
    }
    else {
      /* istanbul ignore else */
      if (this.currentSortOrder === 'desc') {
        this.currentSortOrder = 'asc';
      }
    }
    this.setSortingDefault();
    this.prepareScholarAttendanceData();
  }

  compareValues(key: any, order = 'asc') {
    return function innerSort(a: any, b: any) {
      /* istanbul ignore else */
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = typeof a[key] === 'string'
        ? a[key].toUpperCase()
        : a[key];
      const varB = typeof b[key] === 'string'
        ? b[key].toUpperCase()
        : b[key];

      let comparison = 0;
      /* istanbul ignore else */
      if (varA > varB) {
        comparison = 1;
      }
      else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc'
        ? comparison * -1
        : comparison;
    };
  }

  public toggleattendance() {
    this.closeEmergencyModal();
    this.showClassBool = !this.showClassBool
      ? true
      : false;
  }

  public openDropdown(
    event: any,
    scIndex: number,
    dayIndex: number,
    attendanceType: string
  ) {
    if (this.tabSelected == 'Check-out') {
      this.currentDissmisedIndex = `Dissmised${scIndex}${dayIndex}`;
    }
    if (this.mobileView) {
      this.openAttandancePopup(event, scIndex, dayIndex);
      this.scIndex = scIndex;
      this.attIndex = dayIndex;
      this.attRecord = attendanceType;
    }
    this.currentAttendanceDDRef = scIndex + '_' + dayIndex;
    this.dayViewScIndex = scIndex;
    this.dayViewDayIndex = dayIndex;
  }

  public openDissmissedView(scIndex: number, attIndex: number) {
    this.currentDissmisedIndex = `Dissmised${scIndex}${attIndex}`;
    this.currentAttendanceDDRef = '';
  }

  public closeCurrentDropdown(
    $event: any,
    scIndex: number,
    dayIndex: number,
    attendanceType: string
  ) {
    /* istanbul ignore else */
    if (this.currentAttendanceDDRef != scIndex + '_' + dayIndex) {
      this.currentAttendanceDDRef = '';
    }
  }

  public closeDropdown() {
    this.currentAttendanceDDRef = this.mobileView
      ? this.currentAttendanceDDRef
      : '';
  }

  public openAttandancePopup(event: any, cellIndex: number, rowIndex: number) {
    this.currentRowIndex = rowIndex;
    this.currentCellIndex = cellIndex;
    this.scholarFName =
      this.scholarAttedanceList[this.currentCellIndex].firstName;
    this.scholarLName =
      this.scholarAttedanceList[this.currentCellIndex].lastName;
    this.fotterPopup =
      this.scholarAttedanceList[this.currentCellIndex].attendance[
        this.currentRowIndex
      ].remarks != ''
        ? true
        : false;
    if (UIkit.modal('#modal-sections')) {
      UIkit.modal('#modal-sections', { bgClose: false }).show();
      this.mobilePopUpOpen = true;
    }
  }

  public showEmergencyModal(event: any, scholarObj: any, index: number) {
    this.closeEmergencyModal();
    if (this.mobileView) {
      this.mobileView = true;
      UIkit.modal('#emergency-section', { bgClose: false }).show();
    }
    if (scholarObj && !scholarObj.emergencyDetails) {
      scholarObj.emergencyDetails = {};
    }
    /* istanbul ignore else */
    if (scholarObj && !scholarObj.familyDetails) {
      scholarObj.familyDetails = [];
    }
    this.scholarDetails = scholarObj;
    this.authorizedPickupAll =
      this.scholarDetails.familyDetails.length > 0
        ? this.scholarDetails.familyDetails.filter(
          (item: any) =>
            (item.allowedToPickup && item.allowedToPickup.toUpperCase()) ===
              'YES'
        )
        : [];

    if (this.authorizedPickupAll.length > 3) {
      this.authorizedPickup = this.authorizedPickupAll.slice(0, 3);
    }
    else {
      this.authorizedPickup = this.authorizedPickupAll.slice(0);
    }

    this.ishowEmergencyDetails = this.mobileView
      ? false
      : true;
    this.index = index;
    /* istanbul ignore else */
    if (!this.mobileView) {
      this.showConfirmationModal(event);
    }
  }

  showConfirmationModal(event: any) {
    setTimeout(async () => {
      /* istanbul ignore else */
      if (document.getElementById('emergency-sections')) {
        const y: any = event.clientY;
        try {
          const visible = await this.isVisible(
            document.getElementById('emergency-sections')
          );
          if (!visible && y > 395) {
            const yAxis = y - 250;
            window.scrollBy(0, yAxis);
          }
        }
        catch (error) {
          const yAxis = y - 250;
          window.scrollBy(0, yAxis);
        }
      }
    }, 10);
  }

  /* istanbul ignore next */
  isVisible(domElement: any) {
    return new Promise((resolve) => {
      const o = new IntersectionObserver(([entry]) => {
        resolve(entry.intersectionRatio === 1);
        o.disconnect();
      });
      o.observe(domElement);
    });
  }

  onClickInput(event: any) {
    event.stopPropagation();
  }

  /* istanbul ignore next */
  onClickInputDay(event: any, scIndex: number, dayIndex: number) {
    this.dayViewScIndex = scIndex;
    this.dayViewDayIndex = dayIndex;
    this.updateTime = scIndex + '' + dayIndex;
    this.editTimeColumn = `timeRow-${scIndex}`;

    if (event && event.cancelable) {
      event.stopPropagation();
    }
  }

  public changeRemark(event: any, rowIndex: number, cellIndex: number) {
    //This is intentional
  }

  onEnter(
    event: any,
    obj: any,
    scIndex: number,
    dayIndex: number,
    keyIndex: number,
    remarks: string
  ) {
    this.currentAttendanceDDRef = '';
    const objData: any = {};
    this.scholarAttedanceRef[
      'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
    ].remarks = remarks;
    const attendance =
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
      ];
    objData.id = attendance.id;
    objData.attendance = attendance.attendance;
    const scholarIds: any = [this.scholarAttedanceList[scIndex].id];
    objData.scholarId = scholarIds;
    objData.attendanceDate = this.makeDateString(attendance.attendanceDate);
    objData.remarks = attendance.remarks;
    objData.type = this.tabSelected === 'Check-in'
      ? '1'
      : '2';
    objData.checkInTime = attendance.checkInTime;

    this.saveAttendance(objData);
    if (this.mobileView) {
      this.closesectionModal();
    }
  }

  /* istanbul ignore next */
  staffLoginCheck($event: any, dayIndex: any) {
    if (this.staffCheck) {
      const attedance = this.scholarAttedanceRef[
        "item_" + this.scholarAttedanceList[0].id + "_" + dayIndex
      ].attendanceDate;
      const attendanceDate = this.makeDateString(new Date(attedance), false);
      if (this.makeDateString(new Date(), false) != attendanceDate) {
        $event.target.style.pointerEvents = "none";
      }
    }
  }

  saveAttendance(objData: any) {
    let dataToSend = {
      id: '',
      scholarProgramSiteMappingId: 0,
      programId:
        this.userDetails.programId === 0
          ? APP_UTILITIES.getCookie('programId')
          : this.userDetails.programId,
      siteId:
        this.userDetails.siteId === 0
          ? APP_UTILITIES.getCookie('siteId')
          : this.userDetails.siteId,
      scholarId: [],
      attendanceDate: '',
      attendance: '',
      remarks: '',
      status: true,
      loggedInUserId: 0,
      checkInTime: '',
      checkOutTime: '',
      type: '0',
    };

    dataToSend = { ...dataToSend, ...objData };
    return attendanceStore.markAttendance(dataToSend).then((attRes: any) => {
      //This is intentional
      if (attRes.status === APP_CONST.RESPONSE_200) {
        this.dayNotesValue = '';
        if (
          attRes.data.attendenceIds != '' &&
          dataToSend.scholarId.length == 0
        ) {
          Vue.prototype.$eventHub.$emit('showAttendanceUndoStripIndicater', {
            component: '',
            stripShow: true,
            attendanceStripShow: true,
            stripText: 'Your scholars have been checked-in.',
          });
          this.statusChangeTimer = setTimeout(() => {
            Vue.prototype.$eventHub.$emit('showAttendanceUndoStripIndicater', {
              component: '',
              stripShow: false,
              attendanceStripShow: false,
              stripText: '',
            });
          }, APP_CONST.AUTO_SAVE_INTERVAL);
        }
        this.attendenceIds = attRes.data.attendenceIds;
      }
      return attRes;
    });
  }

  /* istanbul ignore next */
  checkInTimeAttendance(scIndex: number, dayIndex: number) {
    const checkIn =
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
      ];
    if (checkIn.attendance == 'A') {
      this.absentDisabled = true;
    }
    else {
      this.absentDisabled = false;
    }
    if (!checkIn.checkInTime) {
      this.currentTime = new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      });
      return this.currentTime;
    }
    else {
      this.currentTime = checkIn.checkInTime;
      if (checkIn.checkInTime.slice(-1) == 'M') {
        return checkIn.checkInTime;
      }
      else {
        return checkIn.checkInTime.length > 10
          ? APP_UTILITIES.formatAMPM(checkIn.checkInTime)
          : checkIn.checkInTime;
      }
    }
  }

  /* istanbul ignore next */
  checkOutTimeDismissed(scIndex: number, dayIndex: number) {
    const checkOutData =
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
      ];
    if (
      !checkOutData.hasOwnProperty('checkOutTime') ||
      checkOutData.checkOutTime == ''
    ) {
      this.currentTime = new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      });
      return this.currentTime;
    }
    else {
      this.currentTime = checkOutData.checkOutTime;
      if (checkOutData.checkOutTime.slice(-1) == 'M') {
        return checkOutData.checkOutTime;
      }
      else {
        return checkOutData.checkInTime.length > 10
          ? APP_UTILITIES.formatAMPM(checkOutData.checkInTime)
          : checkOutData.checkInTime;
      }
    }
  }

  updateTimeInWeek(event: any, scIndex: number, dayIndex: number) {
    let weektime =
      this.scholarAttedanceList[scIndex].attendance[dayIndex].checkInTime;
    /* istanbul ignore else */
    if (this.tabSelectedValue == 'Week') {
      const splitCurrentTimeDay = weektime.split(/:| /);
      /* istanbul ignore else */
      if (
        splitCurrentTimeDay[0] == 'h' ||
        splitCurrentTimeDay[1] == 'mm' ||
        splitCurrentTimeDay[2] == 'A' ||
        splitCurrentTimeDay[2] == 'P' ||
        weektime == ''
      ) {
        this.currentTime = new Date().toLocaleTimeString('en-US', {
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
        });
        weektime = this.currentTime + ' ';
      }
    }

    this.$set(
      this.scholarAttedanceList[scIndex].attendance[dayIndex],
      'checkInTime',
      weektime
    );
    this.scholarAttedanceList = [...this.scholarAttedanceList];
  }

  markIndividualAttendance(
    event: any,
    scIndex: number,
    dayIndex: number,
    attendanceTypeIndex: number,
    remarks: string
  ) {
    const checkInTimeIndividual =
      this.scholarAttedanceList[scIndex].attendance[dayIndex].checkInTime;
    this.currentAttendanceDDRef = '';
    const objData: any = {};
    this.scholarAttedanceRef[
      'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
    ].remarks = remarks;
    const attendance =
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
      ];
    objData.id = attendance.id;
    const previousAttendance = attendance.attendance;
    objData.attendance =
      attendanceTypeIndex === 3
        ? 'NA'
        : this.attedanceList[attendanceTypeIndex].attValue;
    const scholarIds: any = [this.scholarAttedanceList[scIndex].id];
    objData.attendanceDate = this.makeDateString(attendance.attendanceDate);
    /* istanbul ignore else */
    if (attendance) {
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
      ].attendance =
        attendance.attendance == 'NA'
          ? 'NA'
          : this.attedanceList[attendanceTypeIndex].attValue;
    }
    if (this.tabSelectedValue == 'Day') {
      if (attendance.attendance == 'A') {
        this.absentDayDisabled = true;
      }
      else {
        this.absentDayDisabled = false;
      }

      if (previousAttendance != attendance.attendance) {
        if (
          (previousAttendance == 'L' || previousAttendance == 'P') &&
          attendance.attendance == 'A'
        ) {
          this.totalCheckInScholarCount--;
        }
        else if (
          previousAttendance == 'A' &&
          (attendance.attendance == 'L' || attendance.attendance == 'P')
        ) {
          this.totalCheckInScholarCount++;
        }
      }
    }
    else {
      if (attendance.attendance == 'A') {
        this.absentDisabled = true;
      }
      else {
        this.absentDisabled = false;
      }
    }

    objData.remarks = attendance.remarks;
    objData.scholarId = scholarIds;
    objData.type = 1;
    this.currentTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    });
    if (this.tabSelectedValue == 'Day') {
      objData.checkInTime =
        attendance.checkInTime && attendance.checkInTime != ''
          ? attendance.checkInTime
          : this.currentTime;
    }
    else {
      objData.checkInTime = checkInTimeIndividual
        ? checkInTimeIndividual
        : this.currentTime;
    }
    const checkIn =
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
      ];
    checkIn.checkInTime = objData.checkInTime;
    this.$set(
      this.scholarAttedanceList[scIndex].attendance[dayIndex],
      'checkInTime',
      objData.checkInTime
    );
    this.$set(
      this.scholarAttedanceList[scIndex].attendance[dayIndex],
      'attendance',
      objData.attendance
    );
    this.scholarAttedanceList = [...this.scholarAttedanceList];
    this.saveAttendance(objData);
    /* istanbul ignore else */
    if (this.mobileView) {
      this.closesectionModal();
    }
  }

  /* istanbul ignore next */
  markIndividualAttendanceDay() {
    if (this.updateTime) {
      this.editTimeColumn = '';
      this.currentAttendanceDDRef = this.mobileView
        ? this.currentAttendanceDDRef
        : '';
      const objData: any = {};
      const attendance =
        this.scholarAttedanceRef[
          'item_' +
            this.scholarAttedanceList[this.dayViewScIndex].id +
            '_' +
            this.dayViewDayIndex
        ];
      objData.id = attendance.id;
      const scholarIds: any = [this.scholarAttedanceList[this.dayViewScIndex].id];
      objData.attendanceDate = this.makeDateString(attendance.attendanceDate);
      /* istanbul ignore else */
      objData.remarks = attendance.remarks;
      objData.scholarId = scholarIds;
      objData.attendance = attendance.attendance;

      /* istanbul ignore else */
      if (this.tabSelectedValue == 'Day') {
        this.splitCurrentTimeDayView();
      }

      if (this.tabSelected == 'Check-in') {
        objData.type = 1;
        objData.checkInTime = this.currentTimeDay.trim();
        const checkIn =
          this.scholarAttedanceRef[
            'item_' +
              this.scholarAttedanceList[this.dayViewScIndex].id +
              '_' +
              this.dayViewDayIndex
          ];
        checkIn.checkInTime = objData.checkInTime.trim();
        this.$set(
          this.scholarAttedanceList[this.dayViewScIndex].attendance[
            this.dayViewDayIndex
          ],
          'checkInTime',
          this.currentTimeDay
        );
        this.$set(
          this.scholarAttedanceList[this.dayViewScIndex].attendance[
            this.dayViewDayIndex
          ],
          'attendance',
          objData.attendance
        );
        this.scholarAttedanceList = [...this.scholarAttedanceList];
      }
      else {
        objData.type = 2;
        objData.checkOutTime = this.currentTimeDay.trim();
        const checkOut =
          this.scholarAttedanceRef[
            'item_' +
              this.scholarAttedanceList[this.dayViewScIndex].id +
              '_' +
              this.dayViewDayIndex
          ];
        checkOut.checkOutTime = objData.checkOutTime.trim();
        this.$set(
          this.scholarAttedanceList[this.dayViewScIndex].attendance[
            this.dayViewDayIndex
          ],
          'checkOutTime',
          this.currentTimeDay
        );
        this.scholarAttedanceList = [...this.scholarAttedanceList];
        const vueLoad1 = JSON.parse(
          JSON.stringify(this.scholarAttedanceList[this.dayViewScIndex])
        );
        this.scholarAttedanceList.splice(this.dayViewScIndex, 1);
        this.scholarAttedanceList.splice(this.dayViewScIndex, 0, vueLoad1);
      }
      this.saveAttendance(objData);
      this.updateTime = '';
    }
  }

  /* istanbul ignore next */
  checkInTimes(ev: any, scIndex?: number) {
    this.editTimeColumn = `timeRow-${scIndex}`;
    if (!this.startTimeBlurr) {
      return false;
    }

    if (this.tabSelectedValue != 'Day') {
      const time: string = ev.target.textContent
        .trim()
        .toUpperCase()
        .replace(/ /g, '');
      this.startTimeBlurr = false;
      if (ev.target.textContent) {
        if (
          APP_UTILITIES.validateHhMm12Format(time) ||
          APP_UTILITIES.validateHhMm12FormatwithoutColon(time)
        ) {
          this.errorObj.startTime.isTimeFormat = false;
          const convertTimeintoColon = APP_UTILITIES.convertTime(time);
          this.currentTime = APP_UTILITIES.checkTimeIs24(convertTimeintoColon)
            ? APP_UTILITIES.convert12hrFormateTime(convertTimeintoColon)
            : convertTimeintoColon;
          if (this.tabSelectedValue != 'Day') {
            const element: any = document.getElementById('startTime');
            element.innerHTML = this.currentTime;
          }
          else {
            const idElement: string =
              'startTimeDay' + this.dayViewScIndex + '' + this.dayViewDayIndex;
            const element: any = document.getElementById(idElement);
            element.innerHTML = this.currentTime;
            this.currentTimeDay = element.innerHTML;
          }
        }
        else {
          this.errorObj.startTime.isTimeFormat = true;
        }
      }
      else {
        if (APP_UTILITIES.validateHhMm12Format(time)) {
          this.errorObj.startTime.isTimeFormat = false;
          this.currentTime = APP_UTILITIES.checkTimeIs24(time)
            ? APP_UTILITIES.convert12hrFormateTime(time)
            : time;
        }
        else {
          this.currentTime = '';
          this.errorObj.startTime.isTimeFormat = true;
        }
      }
    }
    else if (this.tabSelectedValue == 'Day') {
      if (ev.displayTime != null) {
        this.currentTimeDay = ev.displayTime;
      }
    }
  }

  markAttendanceForTheColumn(
    event: any,
    dayIndex: any,
    isBulk: boolean,
    scIndex: any
  ) {
    if (this.staffCheck) {
      const attendanceData =
        this.scholarAttedanceRef[
          'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
        ].attendanceDate;
      const attendanceDate = this.makeDateString(new Date(attendanceData));
      if (this.makeDateString(new Date()) != attendanceDate) {
        return;
      }
    }
    this.currentAttendanceDDRef = '';
    this.currentClickedDayIndex = isBulk
      ? dayIndex
      : '-1';
    this.showSingleClickedDay =
      this.tabSelectedValue == 'Week' && !isBulk
        ? `bulk_${scIndex}_${dayIndex}`
        : '';
    const objData: any = {};
    objData.id = 0;
    objData.attendance = 'P';
    objData.checkInTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    });
    objData.checkInTime = objData.checkInTime.replace(/^0(?:0:0?)?/, '');
    objData.type = 1;
    objData.IsBulk = this.tabSelectedValue == 'Week' && isBulk
      ? true
      : false;
    objData.group = this.activeGroup;
    objData.classId = this.currentSelectedClass.id;
    const scholarIds: any = [];
    if (isBulk) {
      this.showSingleClickedDay = '';
      for (let i = 0; i < this.totalScholarCount; i++) {
        const attendance: any =
          this.scholarAttedanceRef[
            'item_' + this.scholarAttedanceList[i].id + '_' + dayIndex
          ];
        if (i == 0) {
          objData.attendanceDate = this.makeDateString(
            attendance.attendanceDate
          );
        }
        if (
          (attendance.attendance == '' || attendance.attendance == 'R') &&
          attendance.attendance != 'NA'
        ) {
          scholarIds.push(this.scholarAttedanceList[i].id);
          this.totalCheckInScholarCount++;
          const attendanceObj =
            this.scholarAttedanceRef[
              'item_' + this.scholarAttedanceList[i].id + '_' + dayIndex
            ];
          /* istanbul ignore else */
          if (attendanceObj) {
            this.scholarAttedanceRef[
              'item_' + this.scholarAttedanceList[i].id + '_' + dayIndex
            ].attendance = 'P';
            this.scholarAttedanceRef[
              'item_' + this.scholarAttedanceList[i].id + '_' + dayIndex
            ].checkInTime = new Date().toLocaleTimeString('en-US', {
              hour: 'numeric',
              hour12: true,
              minute: 'numeric',
            });
            this.$set(
              this.scholarAttedanceList[i].attendance[dayIndex],
              'checkInTime',
              objData.checkInTime
            );
            this.$set(
              this.scholarAttedanceList[i].attendance[dayIndex],
              'attendance',
              objData.attendance
            );
            this.scholarAttedanceList = [...this.scholarAttedanceList];
          }
        }
      }

      objData.scholarId = this.tabSelectedValue == 'Week'
        ? []
        : scholarIds;
      this.saveAttendance(objData).then((attRes) => {
        if (attRes.status === APP_CONST.RESPONSE_200) {
          this.analyticsService.track(
            analyticsEventNames.ATTENDANCE_PARTICIPANT_CHECKED_IN
          );
        }
      });
    }
  }

  downloadAttendance() {
    this.isPdfPrintInProgress = true;
    this.prepareScholarAttendanceData(true);
  }

  preparePrintAndDownloadURL() {
    const programId =
      this.userDetails.programId === 0
        ? APP_UTILITIES.getCookie('programId')
        : this.userDetails.programId;
    const siteId =
      this.userDetails.siteId === 0
        ? APP_UTILITIES.getCookie('siteId')
        : this.userDetails.siteId;
    let url = process.env.VUE_APP_BASE_URL + 'attendance/download?';
    url += 'ProgramId=' + programId + '&';
    url += 'SiteId=' + siteId + '&';

    url += 'classId=' + this.currentSelectedClass.id + '&';
    url
      += 'startDate=' + this.makeDateString(this.currentWeek.weekStartDate) + '&';
    url += 'endDate=' + this.makeDateString(this.currentWeek.weekEndDate);
    return url;
  }

  printAttendance() {
    printJS(this.preparePrintAndDownloadURL());
  }

  concateUserName(userData: any) {
    return userData.firstName + ' ' + userData.lastName;
  }
  goToRosterPage() {
    this.$router.push('roster');
  }

  goToDataDashBoardPage() {
    this.$router.push('dashboard');
  }

  resetAttendanceDDItemsList() {
    this.attedanceList = [
      {
        type: 'Present',
        selectedValue: '',
        attValue: 'P',
        icon: 'present.svg',
      },
      {
        type: 'Absent',
        selectedValue: '',
        attValue: 'A',
        icon: 'absent-icon.svg',
      },
      {
        type: 'Late',
        remark: '',
        selectedValue: '',
        attValue: 'L',
        icon: 'vector.svg',
      },
    ];
  }

  closeEmergencyModal() {
    if (UIkit.modal('#emergency-section')) {
      UIkit.modal('#emergency-section').hide();
    }
    this.authorizedPickup = [];
    this.authorizedPickupAll = [];
    this.showSeeMoreButtonEmerPopup = true;
    this.showSeeLessButtonEmerPopup = false;
    this.ishowEmergencyDetails = false;
    this.index = null;
  }

  formatPhoneNumber(phoneNumberString: any) {
    return APP_UTILITIES.formatNumber(phoneNumberString);
  }

  callHover(
    event1: string,
    event2: string,
    name: string,
    value: string,
    device?: string
  ) {
    if (event1 && event1.length && event2 && event2.length) {
      this.hoverText = event2 + ' ' + event2;
    }
    else {
      this.hoverText = event1;
    }
    /* istanbul ignore else */
    if (
      (this.hoverText.length > 13 && value != 'arrivalplan') ||
      (this.hoverText.length > 17 && value == 'arrivalplan') ||
      (device && device == 'mobile')
    ) {
      this.hover = true;
      this.uniqueValue = name;
      this.nameValue = value;
    }
  }

  callHoverOut(event: string) {
    this.hover = false;
    this.hoverText = event;
  }

  closesectionModal() {
    if (this.mobileView) {
      this.currentAttendanceDDRef = '';
      if (UIkit.modal('#modal-sections')) {
        UIkit.modal('#modal-sections').hide();
      }
      this.fotterPopup = false;
    }
  }

  async makePDF() {
    const breakHieght = 1275;
    const scale = 1;
    const threshold = 99;
    const width = 1157;
    const dateObj = new Date();
    let currentDate = APP_UTILITIES.getSlashedDates(dateObj);
    currentDate = currentDate.replace(/-/g, '_');
    await Printutility.getPrintView(
      'Attendance-data-print',
      breakHieght,
      width,
      scale,
      this.siteName + '_' + currentDate,
      'Attendance-header-print',
      threshold,
      false,
      false
    );
    this.isPdfPrintInProgress = false;
  }

  setSortingDefault() {
    const createKey = this.authKey + '_attendance_sort';
    const sortOrder = this.currentSortOrder
      ? this.currentSortOrder
      : 'asc';
    APP_UTILITIES.setSortingOnSession(
      createKey,
      this.currentSortedColumn,
      sortOrder,
      this.weekSort
    );
  }

  getSortingDefault() {
    const createKey = this.authKey + '_attendance_sort';
    let sortVal: string | null = '';
    if (APP_UTILITIES.getSortingOnSession(createKey)) {
      sortVal = APP_UTILITIES.getSortingOnSession(createKey);
      const sortObj: { key: string; dir: string; customColumn?: string } = sortVal
        ? JSON.parse(sortVal)
        : {};
      this.currentSortedColumn = sortObj.key;
      this.weekSort = sortObj.customColumn
        ? sortObj.customColumn
        : '';
      this.firstNameSorted = true;
      this.lastNameSorted = false;
      this.transportationSorted = false;
      this.gradeSorted = false;
      this.currentSortOrder = sortObj.dir == 'asc'
        ? 'desc'
        : 'asc';
      this.sortHeaders(sortObj.key);
    }
    else {
      this.currentSortOrder = 'asc';
      this.setSortingDefault();
    }
  }

  checkResizeAndOrientation() {
    this.calculateStaffHeight();
    this.calculateHeaderHeight();
  }

  applySortingWithAuth() {
    this.authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : '';
    /* istanbul ignore else */
    if (this.authKey) {
      this.getSortingDefault();
    }
  }

  showMoreEmerPopup() {
    this.authorizedPickup = this.authorizedPickupAll.slice(0);
    this.showSeeMoreButtonEmerPopup = false;
    this.showSeeLessButtonEmerPopup = true;
  }

  showLessEmerPopup() {
    this.authorizedPickup = [];
    this.showSeeLessButtonEmerPopup = false;
    this.showSeeMoreButtonEmerPopup = true;
    this.authorizedPickup = this.authorizedPickupAll.slice(0, 3);
  }

  getDataForPage(pageNumber: any) {
    if (!this.isGroupChange) {
      this.page = pageNumber;
      this.currentDissmisedIndex = '';
      this.prepareScholarAttendanceData();
    }
  }

  isPaginatioShow() {
    if (!this.mobileView) {
      return this.totalActivePagingScholar > 25
        ? true
        : false;
    }
    else {
      return this.totalActivePagingScholar > 10
        ? true
        : false;
    }
  }

  /* istanbul ignore next */
  onlyNumber($event: KeyboardEvent) {
    const allowedAlphabets = ['A', 'a', 'P', 'p', 'M', 'm'];
    const keysAllowed: string[] = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      ':',
      ' ',
      'A',
      'a',
      'P',
      'p',
      'M',
      'm',
    ];
    const keyPressed: string = $event.key;
    const eventTarget: any = $event.target;
    if (
      !keysAllowed.includes(keyPressed) ||
      (allowedAlphabets.includes(keyPressed) &&
        eventTarget.textContent
          .toLowerCase()
          .includes(keyPressed.toLowerCase()))
    ) {
      $event.preventDefault();
    }
  }

  /* istanbul ignore next */
  checkLength($event: any) {
    if (
      $event.target.textContent.length > 8 &&
      $event.keyCode !== 8 &&
      $event.keyCode !== 37 &&
      $event.keyCode !== 39
    ) {
      $event.preventDefault();
    }
    if ($event.keyCode == 13) {
      $event.preventDefault();
    }
  }

  /* istanbul ignore next */
  public onInputStartTime($event: any, scIndex?: number) {
    this.startTimeBlurr = true;
    this.editTimeColumn = `timeRow-${scIndex}`;
  }

  markDismissedScholar(attIndex: number, scIndex: number) {
    this.currentDissmisedIndex = `Dissmised${scIndex}${attIndex}`;
    const objData: any = {};
    objData.id = 0;
    objData.checkOutTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    });
    objData.checkOutTime = objData.checkOutTime.replace(/^0(?:0:0?)?/, '');
    objData.type = 2;
    objData.remarks = this.note;
    const scholarIds: any = [];
    const attendance =
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + attIndex
      ];

    /* istanbul ignore else */
    if (
      !attendance.hasOwnProperty('checkOutTime') ||
      !attendance.checkOutTime
    ) {
      this.totalCheckedOutScholarCount++;
      scholarIds.push(this.scholarAttedanceList[scIndex].id);
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + attIndex
      ].checkOutTime = new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      });
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + attIndex
      ].checkOutTime = this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + attIndex
      ].checkOutTime.replace(/^0(?:0:0?)?/, '');
      objData.attendance = attendance.attendance;
      objData.attendanceDate = attendance.attendanceDate;
      this.$set(
        this.scholarAttedanceList[scIndex].attendance[attIndex],
        'checkOutTime',
        objData.checkOutTime
      );
      this.scholarAttedanceList = [...this.scholarAttedanceList];
    }

    objData.scholarId = scholarIds;
    /* istanbul ignore else */
    if (scholarIds.length > 0) {
      this.saveAttendance(objData).then((attRes) => {
        if (attRes.status === APP_CONST.RESPONSE_200) {
          this.analyticsService.track(
            analyticsEventNames.ATTENDANCE_PARTICIPANT_CHECKED_OUT
          );
        }
      });
    }
  }

  /* istanbul ignore next */
  openDetailsSidePanel(record: any) {
    this.currectSelectedRecord = [];
    const payload: any = {
      ssmId: record.scholarProgramSiteMappingId,
      startDate: this.makeDateString(this.currentWeek.weekStartDate),
      endDate: this.makeDateString(this.currentWeek.weekEndDate),
    };
    if (UIkit.offcanvas('#offcanvas-flip-openAttendanceDetails')) {
      UIkit.offcanvas('#offcanvas-flip-openAttendanceDetails').show();
    }
    getScholarDetails(payload).then((response: any) => {
      /* istanbul ignore else */
      if (response.status == 200) {
        this.currectSelectedRecord = response.data;
      }
    });
  }

  checkOutsideClick() {
    if (this.currentTimeDay == '') {
      this.markIndividualAttendanceDay();
    }
  }

  isEllipsisActive(classroomName: string, uniqueValue: string, event: any) {
    const target = event.target;
    /* istanbul ignore else */
    if (target.offsetWidth < target.scrollWidth) {
      this.hover = true;
      this.hoverText = classroomName;
      this.uniqueValue = uniqueValue;
      const boundBox =
        event && event.target && event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
    }
  }

  singleScholarCheckIn(event: any, scIndex: number, attIndex: number) {
    this.currentAttendanceDDRef = '';
    if (this.staffCheck) {
      const attendance =
        this.scholarAttedanceRef[
          'item_' + this.scholarAttedanceList[0].id + '_' + attIndex
        ].attendanceDate;
      const attendanceDate = this.makeDateString(new Date(attendance));
      /* istanbul ignore else */
      if (this.makeDateString(new Date()) != attendanceDate) {
        return;
      }
    }
    this.singleScholarCheckinIndex = `single-${scIndex}-${attIndex}`;
    this.currentClickedDayIndex = attIndex;
    this.mobileOrangeDot = `mobile-OrangeDot-${attIndex}-${scIndex}`;
    const objData: any = {};
    objData.id = 0;
    objData.attendance = 'P';
    objData.checkInTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    });
    objData.checkInTime = objData.checkInTime.replace(/^0(?:0:0?)?/, '');
    objData.type = 1;
    const scholarIds: any = [];
    const attendance: any =
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + attIndex
      ];
    objData.remarks = (attendance && attendance.remarks) || '';
    objData.attendanceDate = this.makeDateString(attendance.attendanceDate);

    /* istanbul ignore else*/
    if (
      (attendance.attendance == '' || attendance.attendance == 'R') &&
      attendance.attendance != 'NA'
    ) {
      scholarIds.push(this.scholarAttedanceList[scIndex].id);
      this.totalCheckInScholarCount++;
      const attendanceObj =
        this.scholarAttedanceRef[
          'item_' + this.scholarAttedanceList[scIndex].id + '_' + attIndex
        ];
      attendanceObj.checkInTime = objData.checkInTime.trim();
      this.$set(
        this.scholarAttedanceList[scIndex].attendance[attIndex],
        'checkInTime',
        objData.checkInTime
      );
      this.$set(
        this.scholarAttedanceList[scIndex].attendance[attIndex],
        'attendance',
        objData.attendance
      );
      this.scholarAttedanceList = [...this.scholarAttedanceList];

      /* istanbul ignore else */
      if (attendanceObj) {
        this.scholarAttedanceRef[
          'item_' + this.scholarAttedanceList[scIndex].id + '_' + attIndex
        ].attendance = 'P';
        this.scholarAttedanceRef[
          'item_' + this.scholarAttedanceList[scIndex].id + '_' + attIndex
        ].checkInTime = new Date().toLocaleTimeString('en-US', {
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
        });
      }
    }
    objData.scholarId = scholarIds;
    /* istanbul ignore else */
    if (scholarIds.length > 0) {
      this.saveAttendance(objData).then((attRes) => {
        if (attRes.status === APP_CONST.RESPONSE_200) {
          this.analyticsService.track(
            analyticsEventNames.ATTENDANCE_PARTICIPANT_CHECKED_IN
          );
        }
      });
    }
  }

  updateNotes(scIndex: number) {
    this.dayNotesIndex = `dayNotes-${scIndex}`;
    const notesRef: any = document.getElementById(`id-day-notes-${scIndex}`);
    this.dayNotesValue = notesRef.innerText;
  }

  limitedNotesChck(event: any, characterLimit: number, scIndex: number) {
    const formElementRef: any = document.getElementById(
      `id-day-notes-${scIndex}`
    );
    /* istanbul ignore else */
    if (formElementRef.innerText.length >= characterLimit) {
      /* istanbul ignore else */
      if (
        event.key != 'Backspace' &&
        event.key != 'Delete' &&
        event.key != 'ArrowLeft' &&
        event.key != 'ArrowRight' &&
        event.key != 'ArrowUp' &&
        event.key != 'ArrowDown'
      ) {
        event.preventDefault();
      }
    }
  }

  saveDayNotes(scIndex: number, dayIndex: number, remark: string) {
    this.note = remark
      ? remark
      : this.dayNotesValue;
    this.scholarAttedanceRef[
      'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
    ].remarks = this.dayNotesValue;
    this.dayNotesValue = this.mobileView
      ? remark
      : this.dayNotesValue;
    this.dayNotesIndex = this.mobileView
      ? `dayNotes-${scIndex}`
      : this.dayNotesIndex;
    /* istanbul ignore else */
    if (this.dayNotesIndex == '') {
      return;
    }
    this.saveNotesRef = document.getElementById(`id-day-notes-${scIndex}`);
    if (
      this.dayNotesValue &&
      this.dayNotesValue.length &&
      this.dayNotesValue.trim().length == 0
    ) {
      this.saveNotesRef.innerText = '';
      this.dayNotesIndex = '';
      this.dayNotesValue = '';
      return;
    }
    else if (
      this.dayNotesValue &&
      this.dayNotesValue.trim().length > 0 &&
      !this.mobileView
    ) {
      this.saveNotesRef.innerText = '';
      this.saveNotesRef.innerText =
        this.dayNotesValue && this.dayNotesValue.trim();
    }

    if (this.updateTime && this.mobileView) {
      this.splitCurrentTimeDayView();
    }
    const objData: any = {};
    const attendance =
      this.scholarAttedanceRef[
        'item_' + this.scholarAttedanceList[scIndex].id + '_' + dayIndex
      ];
    objData.id = attendance.id;
    objData.attendance = attendance.attendance;
    const scholarIds: any = [this.scholarAttedanceList[scIndex].id];
    objData.scholarId = scholarIds;
    objData.attendanceDate = this.makeDateString(attendance.attendanceDate);
    objData.remarks = this.dayNotesValue && this.dayNotesValue.trim();
    objData.type = this.tabSelected === 'Check-in'
      ? '1'
      : '2';
    objData.checkInTime =
      this.updateTime && this.mobileView && this.tabSelected == 'Check-in'
        ? this.currentTimeDay
        : attendance.checkInTime
          ? attendance.checkInTime
          : '';
    objData.checkOutTime =
      this.updateTime && this.mobileView && this.tabSelected == 'Check-out'
        ? this.currentTimeDay
        : attendance.checkOutTime
          ? attendance.checkOutTime
          : '';

    if (this.updateTime && this.mobileView && this.tabSelected === 'Check-in') {
      this.$set(
        this.scholarAttedanceList[this.dayViewScIndex].attendance[
          this.dayViewDayIndex
        ],
        'checkInTime',
        this.currentTimeDay
      );
      this.scholarAttedanceList = [...this.scholarAttedanceList];
    }
    else if (
      this.updateTime &&
      this.mobileView &&
      this.tabSelected === 'Check-out'
    ) {
      this.$set(
        this.scholarAttedanceList[this.dayViewScIndex].attendance[
          this.dayViewDayIndex
        ],
        'checkOutTime',
        this.currentTimeDay
      );
      this.scholarAttedanceList = [...this.scholarAttedanceList];
    }

    if (this.dayNotesIndex == `dayNotes-${scIndex}`) {
      this.saveAttendance(objData);
      this.dayNotesIndex = '';
    }
    this.mobileNotesIndex = '';
  }
  filterColumns(columns: any) {
    this.filteredColumns = columns;
  }

  async downloadPDF(data: any) {
    const weekInfo: any = [];
    const dates: any = [];
    const columnsName: string[] = [];
    this.isDownlodProgress = true;
    this.programId =
      this.userDetails.programId === 0
        ? APP_UTILITIES.getCookie('programId')
        : this.userDetails.programId;
    this.siteId =
      this.userDetails.siteId === 0
        ? APP_UTILITIES.getCookie('siteId')
        : this.userDetails.siteId;
    const startDate = APP_UTILITIES.formatFullYearDate(
      data[0].attendance[0].attendanceDate
    );
    const staffName = this.returnTeacherData(this.teacherList);
    this.filteredColumns &&
      this.filteredColumns.forEach((element1: any, index: number) => {
        data &&
          data[0].attendance &&
          data[0].attendance.forEach((element: any, index1: number) => {
            if (index == 1) {
              weekInfo.push({
                date: element.attendanceDate,
                index: index1 + (this.attendanceEnhancementsEnabled
                  ? 2
                  : 1), // 2 is added to skip the first two columns (Grade & Transportation)
              });
              this.endDate = APP_UTILITIES.formatFullYearDate(
                element.attendanceDate
              );
            }
          });
      });

    const dayColumnKeys = APP_CONST.WEEK_DAYS.map(({ value }) => value);
    this.filteredColumns &&
      this.filteredColumns.forEach((element1: any, index: number) => {
        if (this.tabSelectedValue == 'Week') {
          weekInfo &&
            weekInfo.forEach((element: any) => {
              if (
                dayColumnKeys.includes(element1.key) &&
                element1.display !== false &&
                element.index == index
              ) {
                dates.push(APP_UTILITIES.formatFullYearDate(element.date));
              }
            });
          if (element1.display && !dayColumnKeys.includes(element1.key)) {
            columnsName.push(element1.name);
          }
        }
        else if (element1.display) {
          columnsName.push(element1.name);
          this.endDate = startDate;
        }
      });
    let formatDate: any = dates.map((index: any) => 'Date=' + index);
    formatDate = formatDate.join('&');
    const columnList =columnsName.map((name) => `ColumnName=${name}`).join('&');
    const type =
      this.tabSelectedValue == 'Week' || this.tabSelected == 'Check-in'
        ? 1
        : 2;
    const sortOrder = this.currentSortOrder == 'asc'
      ? 1
      : 2;
    const sortField =
      (this.currentSortedColumn == 'Transportation'
        ? this.tabSelected == 'Check-in'
          ? 'ArrivalPlan'
          : ' Dismissalplan'
        : this.currentSortedColumn) ||
      (this.currentSortedColumn == 'Transportation' &&
      this.tabSelectedValue == 'Week'
        ? 'arrivalPlan'
        : this.currentSortedColumn);
    const payload = {
      Date: formatDate
        ? formatDate
        : null,
      ProgramId: this.programId,
      SiteId: this.siteId,
      StaffName: staffName,
      ClassRoomName: this.currentSelectedClass.classroomName,
      StartDate: startDate,
      EndDate: this.endDate,
      Group: this.activeGroup || 1,
      Type: type,
      IsReport: true,
      ColumnName: columnList,
      sortDir: sortOrder,
      SortField: sortField,
      tabValue: this.tabSelectedValue,
      weekDay: this.weekSort,
      classid: this.currentSelectedClass.id,
    };
    await downloadAttendancePDF(payload).then((response: any) => {
      if (response.status === APP_CONST.RESPONSE_200) {
        const fileName =
          this.siteName +
          '_' +
          this.getFileName(startDate) +
          '_' +
          this.getFileName(this.endDate);
        APP_UTILITIES.downloadPDF(response, fileName);
        this.isDownlodProgress = false;
      }
    });
  }

  getFileName(date: string) {
    const startDateObj = new Date(date);
    const month = String(startDateObj.getMonth() + 1).padStart(2, '0');
    const day = String(startDateObj.getDate()).padStart(2, '0');
    const year = startDateObj.getFullYear().toString();
    return month + '_' + day + '_' + year;
  }

  async resetData(data: any, index: number) {
    data = this.mobileView
      ? this.scholarAttedanceList[index]
      : data;
    const programId =
      this.userDetails.programId === 0
        ? APP_UTILITIES.getCookie('programId')
        : this.userDetails.programId;
    const siteId =
      this.userDetails.siteId === 0
        ? APP_UTILITIES.getCookie('siteId')
        : this.userDetails.siteId;
    const date = APP_UTILITIES.formatFullYearDate(
      data.attendance[0].attendanceDate
    );
    const type = this.tabSelected == 'Check-in'
      ? 1
      : 2;
    const payload = {
      scholarSiteMappingId: data.scholarProgramSiteMappingId,
      attendanceDate: date,
      type: type,
      programId: programId,
      siteId: siteId,
    };
    const response = await resetData(payload);
    if (response.status == APP_CONST.RESPONSE_200) {
      this.prepareScholarAttendanceData();
      this.closeDropdown();
      if (this.tabSelected == 'Check-in') {
        this.singleScholarCheckinIndex = '';
      }
      else {
        this.currentDissmisedIndex = '';
      }
      if (this.mobileView) {
        this.closesectionModal();
      }
    }
  }
  pasteNotesValue(e: any, characterLimit: number, scIndex: number) {
    const notesRef: any = document.getElementById(`id-day-notes-${scIndex}`);
    e.preventDefault();
    const pastedData = e.clipboardData
      ? (e.originalEvent || e).clipboardData.getData('text/plain')
      : // For IE
      (<any>window).clipboardData
        ? (<any>window).clipboardData.getData('Text')
        : '';
    if (
      notesRef &&
      notesRef.innerText &&
      !notesRef.innerText.length &&
      pastedData.length > characterLimit
    ) {
      e.stopPropagation();
      e.preventDefault();
      notesRef.innerText = pastedData.slice(0, characterLimit);
    }
    else if (
      notesRef &&
      notesRef.innerText &&
      notesRef.innerText.length &&
      notesRef.innerText.length + pastedData.length > characterLimit
    ) {
      e.stopPropagation();
      e.preventDefault();
      notesRef.innerText =
        notesRef.innerText +
        pastedData.slice(0, characterLimit - notesRef.innerText.length);
    }
    else if (
      notesRef &&
      notesRef.innerText &&
      !notesRef.innerText.length &&
      pastedData.length < characterLimit
    ) {
      e.stopPropagation();
      e.preventDefault();
      notesRef.innerText = pastedData;
    }
    this.dayNotesValue = notesRef.innerText;
  }

  changeDisplayDayAttendanceColumn(displayValue: any) {
    const tempCurrentWeek = JSON.parse(JSON.stringify(this.currentWeek));
    tempCurrentWeek.days[0]['display'] = displayValue;

    this.currentWeek = JSON.parse(JSON.stringify(tempCurrentWeek));

    const tempScholarList = JSON.parse(JSON.stringify(this.scholarAttedanceList));
    tempScholarList.forEach((element: any) => {
      element.attendance[0]['attendanceDisplay'] = displayValue;
    });

    this.scholarAttedanceList = JSON.parse(JSON.stringify(tempScholarList));
    this.singleScholarCheckinIndex = '';
    this.currentDissmisedIndex = '';
    const movaableTable = this.$refs && (this.$refs.movaableTable as any);
    if (movaableTable) {
      setTimeout(() => {
        movaableTable.resizableTable();
        movaableTable.updateTableView();
      }, 50);
    }
    attendanceStore.mutateScholarDisplay(tempScholarList);
  }

  changeDisplayWeekAttendanceColumn(display: boolean, key: string) {
    const tempCurrentWeek = JSON.parse(JSON.stringify(this.currentWeek));
    let date: any;
    tempCurrentWeek.days.forEach((week: any) => {
      if (week.day == key) {
        date = week.date;
        week['display'] = display;
      }
    });
    this.currentWeek = JSON.parse(JSON.stringify(tempCurrentWeek));

    const tempScholarList = JSON.parse(JSON.stringify(this.scholarAttedanceList));

    tempScholarList.forEach((scholar: any) => {
      scholar.attendance.forEach((att: any) => {
        const attendanceDate = att.attendanceDate;
        if (date == new Date(attendanceDate)) {
          att['attendanceDisplay'] = display;
        }
      });
    });

    this.currentClickedDayIndex = '';
    this.scholarAttedanceList = JSON.parse(JSON.stringify(tempScholarList));
    const movaableTable = this.$refs && (this.$refs.movaableTable as any);
    if (movaableTable) {
      setTimeout(() => {
        movaableTable.resizableTable();
        movaableTable.updateTableView();
      }, 50);
    }
    attendanceStore.mutateScholarDisplay(tempScholarList);
  }

  changeAllAttendanceColumns(display: boolean) {
    if (this.tabSelectedValue == 'Day') {
      const tempCurrentWeek = JSON.parse(JSON.stringify(this.currentWeek));
      let date: any;
      tempCurrentWeek.days[0]['display'] = display;
      this.currentWeek = JSON.parse(JSON.stringify(tempCurrentWeek));

      const tempScholarList = JSON.parse(
        JSON.stringify(this.scholarAttedanceList)
      );
      tempScholarList.forEach((element: any) => {
        element.attendance[0]['attendanceDisplay'] = display;
      });

      this.scholarAttedanceList = JSON.parse(JSON.stringify(tempScholarList));
      this.singleScholarCheckinIndex = '';
      this.currentDissmisedIndex = '';
      attendanceStore.mutateScholarDisplay(tempScholarList);
    }
    else if (this.tabSelectedValue == 'Week') {
      const tempCurrentWeek = JSON.parse(JSON.stringify(this.currentWeek));
      tempCurrentWeek.days.forEach((week: any) => {
        week['display'] = display;
      });
      this.currentWeek = JSON.parse(JSON.stringify(tempCurrentWeek));

      const tempScholarList = JSON.parse(
        JSON.stringify(this.scholarAttedanceList)
      );

      tempScholarList.forEach((scholar: any) => {
        scholar.attendance.forEach((att: any) => {
          att['attendanceDisplay'] = display;
        });
      });

      this.currentClickedDayIndex = '';
      this.scholarAttedanceList = JSON.parse(JSON.stringify(tempScholarList));
      attendanceStore.mutateScholarDisplay(tempScholarList);
    }
    const movaableTable = this.$refs && (this.$refs.movaableTable as any);
    if (movaableTable) {
      setTimeout(() => {
        movaableTable.resizableTable();
        movaableTable.updateTableView();
      }, 50);
    }
  }

  showAllHeaders() {
    const tempCheckinHeaders = JSON.parse(JSON.stringify(this.checkInHeaders));
    tempCheckinHeaders.forEach((header: any) => {
      header.display = true;
    });

    const tempCheckOutHeaders = JSON.parse(JSON.stringify(this.checkOutHeaders));
    tempCheckOutHeaders.forEach((header: any) => {
      header.display = true;
    });

    attendanceStore.mutateCheckinHeaders(tempCheckinHeaders);
    attendanceStore.mutateCheckOutHeaders(tempCheckOutHeaders);
    attendanceStore.mutateWeekTransportation(true);
  }

  mapDisplayValues() {
    if (this.tabSwitch) {
      return;
    }

    const mapScholarList = JSON.parse(JSON.stringify(this.scholarAttedanceList));
    const mapCurrentWeekList = JSON.parse(JSON.stringify(this.currentWeek));
    let date: any;

    if (this.tabSelectedValue == 'Day') {
      this.filteredColumns.forEach((column: any) => {
        if (column.name == 'Check-in' || column.name == 'Check-out') {
          mapScholarList.forEach((scholars: any) => {
            scholars.attendance[0]['attendanceDisplay'] = column.display;
          });

          mapCurrentWeekList.days[0]['display'] = column.display;
        }
      });
      this.scholarAttedanceList = JSON.parse(JSON.stringify(mapScholarList));
      this.currentWeek = JSON.parse(JSON.stringify(mapCurrentWeekList));
    }
    else if (this.tabSelectedValue == 'Week') {
      this.filteredColumns.forEach((column: any) => {
        mapCurrentWeekList.days.forEach((week: any) => {
          if (week.day == column.key) {
            date = week.date;
            week['display'] = column.display;
          }
        });

        mapScholarList.forEach((scholar: any) => {
          scholar.attendance.forEach((att: any) => {
            const attendanceDate = att.attendanceDate;
            if (date == new Date(attendanceDate)) {
              att['attendanceDisplay'] = column.display;
            }
          });
        });
      });
      this.scholarAttedanceList = JSON.parse(JSON.stringify(mapScholarList));
      this.currentWeek = JSON.parse(JSON.stringify(mapCurrentWeekList));
    }
  }

  popUpMobileIndex(scIndex: number, attIndex: number) {
    this.mobileNotesIndex = `mobile-notes-${scIndex}-${attIndex}`;
  }

  splitCurrentTimeDayView() {
    const splitCurrentTimeDay = this.currentTimeDay.split(/:| /);
    /* istanbul ignore else */
    if (
      splitCurrentTimeDay[0] == 'h' ||
      splitCurrentTimeDay[1] == 'mm' ||
      splitCurrentTimeDay[2] == 'A' ||
      splitCurrentTimeDay[2] == 'P' ||
      this.currentTimeDay == ''
    ) {
      this.currentTime = new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      });
      this.currentTimeDay = this.currentTime + ' ';
    }
  }

  getHeadersFromStorage() {
    this.headersFromStorage = [];
    let programId: any = APP_UTILITIES.getCookie('programId');
    programId = JSON.parse(programId);
    let siteId: any = APP_UTILITIES.getCookie('siteId');
    siteId = JSON.parse(siteId);
    if (this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day') {
      /* istanbul ignore else */
      if (localStorage.getItem('CheckinGearSettingsData')) {
        let checInHeaders: any = localStorage.getItem(
          'CheckinGearSettingsData'
        );
        checInHeaders = JSON.parse(checInHeaders);
        /* istanbul ignore else */
        if (checInHeaders.hasOwnProperty([programId])) {
          /* istanbul ignore else */
          if (checInHeaders[programId].hasOwnProperty([siteId])) {
            this.headersFromStorage = checInHeaders[programId][siteId];
          }
        }
      }
    }
    else if (this.tabSelected == 'Check-out') {
      /* istanbul ignore else */
      if (localStorage.getItem('CheckOutGearSettingsData')) {
        let checkOutHeaders: any = localStorage.getItem(
          'CheckOutGearSettingsData'
        );
        checkOutHeaders = JSON.parse(checkOutHeaders);
        /* istanbul ignore else */
        if (checkOutHeaders.hasOwnProperty([programId])) {
          /* istanbul ignore else */
          if (checkOutHeaders[programId].hasOwnProperty([siteId])) {
            this.headersFromStorage = checkOutHeaders[programId][siteId];
          }
        }
      }
    }
    if (this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week') {
      /* istanbul ignore else */
      if (localStorage.getItem('CheckinWeekGearSettingsData')) {
        let checInWeekHeaders: any = localStorage.getItem(
          'CheckinWeekGearSettingsData'
        );
        checInWeekHeaders = JSON.parse(checInWeekHeaders);
        /* istanbul ignore else */
        if (checInWeekHeaders.hasOwnProperty([programId])) {
          /* istanbul ignore else */
          if (checInWeekHeaders[programId].hasOwnProperty([siteId])) {
            this.headersFromStorage = checInWeekHeaders[programId][siteId];
          }
        }
      }
    }
  }

  getResizeFromStorage() {
    let programId: any = APP_UTILITIES.getCookie('programId');
    programId = JSON.parse(programId);
    let siteId: any = APP_UTILITIES.getCookie('siteId');
    siteId = JSON.parse(siteId);
    if (this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day') {
      /* istanbul ignore else */
      if (localStorage.getItem('CheckinResizeData')) {
        let checInResizeObj: any = localStorage.getItem('CheckinResizeData');
        checInResizeObj = JSON.parse(checInResizeObj);
        /* istanbul ignore else */
        if (checInResizeObj.hasOwnProperty([programId])) {
          /* istanbul ignore else */
          if (checInResizeObj[programId].hasOwnProperty([siteId])) {
            attendanceStore.mutateCheckInResizeObj(
              checInResizeObj[programId][siteId]
            );
          }
        }
      }
    }
    else if (this.tabSelected == 'Check-out') {
      /* istanbul ignore else */
      if (localStorage.getItem('CheckOutResizeData')) {
        let checOutResizeObj: any = localStorage.getItem('CheckOutResizeData');
        checOutResizeObj = JSON.parse(checOutResizeObj);
        /* istanbul ignore else */
        if (checOutResizeObj.hasOwnProperty([programId])) {
          /* istanbul ignore else */
          if (checOutResizeObj[programId].hasOwnProperty([siteId])) {
            attendanceStore.mutateCheckOutResizeObject(
              checOutResizeObj[programId][siteId]
            );
          }
        }
      }
    }
    else if (
      this.tabSelected == 'Check-in' &&
      this.tabSelectedValue == 'Week'
    ) {
      /* istanbul ignore else */
      if (localStorage.getItem('CheckinWeekResizeData')) {
        let checkInWeekResizeObj: any = localStorage.getItem(
          'CheckinWeekResizeData'
        );
        checkInWeekResizeObj = JSON.parse(checkInWeekResizeObj);
        /* istanbul ignore else */
        if (checkInWeekResizeObj.hasOwnProperty([programId])) {
          /* istanbul ignore else */
          if (checkInWeekResizeObj[programId].hasOwnProperty([siteId])) {
            attendanceStore.mutateWeekResizeObj(
              checkInWeekResizeObj[programId][siteId]
            );
          }
        }
      }
    }
  }

  mapCheckInHeadersFromStorage(headers: any) {
    const tempScholarList = JSON.parse(JSON.stringify(this.scholarAttedanceList));
    const tempCurrentWeek = JSON.parse(JSON.stringify(this.currentWeek));
    const dates: any = [];
    if (headers.length && this.tabSelectedValue == 'Day') {
      headers.forEach((header: any) => {
        if (header.name == 'Check-in' || header.name == 'Check-out') {
          tempScholarList.forEach((scholar: any) => {
            scholar.attendance.forEach((att: any) => {
              att['attendanceDisplay'] = header.display;
            });
          });
          tempCurrentWeek.days.forEach((day: any) => {
            day['display'] = header.display;
          });
        }
      });
      this.scholarAttedanceList = JSON.parse(JSON.stringify(tempScholarList));
      this.currentWeek = JSON.parse(JSON.stringify(tempCurrentWeek));
      if (this.tabSelected == 'Check-in') {
        attendanceStore.mutateCheckinHeaders(headers);
      }
      else if (this.tabSelected == 'Check-out') {
        attendanceStore.mutateCheckOutHeaders(headers);
      }
    }
    else if (headers.length && this.tabSelectedValue == 'Week') {
      headers.forEach((header: any) => {
        tempCurrentWeek.days.forEach((week: any) => {
          if (week.day == header.key) {
            dates.push({ display: header.display, weekDate: week.date });
            week['display'] = header.display;
          }
          else if (header.key == 'transport') {
            attendanceStore.mutateWeekTransportation(header.display);
          }
        });
      });

      dates.forEach((date: any) => {
        tempScholarList.forEach((scholar: any) => {
          scholar.attendance.forEach((att: any) => {
            const attendanceDate = att.attendanceDate;
            if (date.weekDate == new Date(attendanceDate)) {
              att['attendanceDisplay'] = date.display;
            }
          });
        });
      });
      this.currentWeek = JSON.parse(JSON.stringify(tempCurrentWeek));
      this.scholarAttedanceList = JSON.parse(JSON.stringify(tempScholarList));
    }
  }

  openSortingDropdown(
    header: any,
    coordObj?: { x: number; y: number },
    dayItem?: any
  ) {
    this.closeSortingDropdown();
    const x = (coordObj && coordObj.x && coordObj.x) || 0;
    const y = (coordObj && coordObj.y && coordObj.y) || 0;
    const xCord = x.toString() + 'px';
    const yCord = y.toString() + 'px';
    this.sortingDropdownStyleObj.left = xCord;
    this.sortingDropdownStyleObj.top = yCord;
    if (header == 'transportation') {
      this.sortingDropdownIndex = header;
      attendanceStore.mutateTransportationFilterState(true);
      this.header = header;
    }
    else if (header === 'grade') {
      this.sortingDropdownIndex = header;
      attendanceStore.mutateGradeFilterState(true);
      this.header = header;
    }
    else if (header == 'Attendance') {
      this.sortingDropdownIndex = dayItem.day;
      dayItem.isOpen = true;
      this.header = dayItem;
      this.header['value'] = header;

      const tempCurrentWeek = JSON.parse(JSON.stringify(this.currentWeek));
      tempCurrentWeek.days.forEach((week: any) => {
        if (week.day == dayItem.day) {
          week['isOpen'] = true;
        }
      });

      this.currentWeek = JSON.parse(JSON.stringify(tempCurrentWeek));
    }
    else {
      this.sortingDropdownIndex = header.value;
      header.isOpen = true;
      this.header = header;
    }
  }

  closeSortingDropdown() {
    const fixedTable = this.$refs && (this.$refs.fixedTable as any);
    const movaableTable = this.$refs && (this.$refs.movaableTable as any);
    if (fixedTable) {
      fixedTable.handleSortingArrows(this.header);
    }
    if (movaableTable) {
      attendanceStore.mutateTransportationFilterState(false);
      attendanceStore.mutateGradeFilterState(false);
      const tempCurrentWeek = JSON.parse(JSON.stringify(this.currentWeek));
      tempCurrentWeek.days.forEach((week: any) => {
        week.isOpen = false;
      });

      this.currentWeek = JSON.parse(JSON.stringify(tempCurrentWeek));
    }
    this.header = {};
    this.sortingDropdownStyleObj = { top: '', left: '' };
    this.sortingDropdownIndex = '';
  }

  sortAttendanceData(sortData: any) {
    let field = '';
    let sortOrder = '';
    let dayItem: any = {};
    if (sortData) {
      field = sortData.sortField
        ? sortData.sortField
        : '';
      sortOrder = sortData.sortDirection
        ? sortData.sortDirection
        : '';
      dayItem = sortData.dayItem
        ? sortData.dayItem
        : {};
    }
    this.currentSortOrder = sortOrder;
    if (field == 'firstName') {
      this.firstNameSorted = true;
      this.lastNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.weekSort = '';
      this.currentSortedColumn = 'firstName';
    }
    else if (field == 'lastName') {
      this.lastNameSorted = true;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.weekSort = '';
      this.currentSortedColumn = 'lastName';
    }
    else if (field === 'grade') {
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.gradeSorted = true;
      this.transportationSorted = false;
      this.weekSort = '';
      this.currentSortedColumn = 'grade';
    }
    else if (field == 'transportation') {
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = true;
      this.weekSort = '';
      this.currentSortedColumn =
        this.tabSelected === 'Check-in'
          ? 'arrivalPlan'
          : 'dismissalPlan';
    }
    else if (field == 'Attendance') {
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.weekSort = dayItem && dayItem.day
        ? dayItem.day
        : '';
      this.weekDay =
        this.tabSelected == 'Check-in' &&
        this.tabSelectedValue == 'Week' &&
        dayItem &&
        dayItem.day
          ? dayItem.day
          : '';
      this.currentSortedColumn = field;
    }
    this.currentDissmisedIndex = '';
    this.singleScholarCheckinIndex;
    this.getHeadersFromStorage();
    this.prepareScholarAttendanceData();
    this.closeSortingDropdown();
    this.isAttendanceDataLoading = this.isLoaderVisible
      ? true
      : this.isAttendanceDataLoading;
  }

  searchAttendance(searchData: any) {
    if (searchData && searchData.searchField == 'Transportation') {
      this.searchField =
        this.tabSelected == 'Check-in'
          ? 'ArrivalPlan'
          : 'Dismissalplan';
      this.searchValue = searchData.searchValue
        ? searchData.searchValue
        : '';
      this.currentSearchingDay = '';
    }
    else if (searchData && searchData.searchField == 'Attendance') {
      if (this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week') {
        this.weekDay =
          searchData && searchData.dayItem && searchData.dayItem.day
            ? searchData.dayItem.day
            : '';
      }
      this.currentSearchingDay =
        this.tabSelectedValue == 'Week'
          ? this.weekDay
          : searchData && searchData.dayItem && searchData.dayItem.day;
      this.searchField = searchData.searchField
        ? searchData.searchField
        : '';
      this.searchValue = searchData.searchValue
        ? searchData.searchValue
        : '';
    }
    else {
      this.searchField = searchData.searchField
        ? searchData.searchField
        : '';
      this.searchValue = searchData.searchValue
        ? searchData.searchValue
        : '';
    }
    if (searchData.fromClearSelection) {
      this.currentSearchingDay = '';
    }
    this.isExactMatch = searchData.isExactMatch;
    this.currentDissmisedIndex = '';
    this.singleScholarCheckinIndex = '';
    this.page = 1;
    this.paginationReset = false;
    this.getHeadersFromStorage();
    this.prepareScholarAttendanceData();
    if (!searchData.fromClearSelection && searchData.searchField) {
      this.closeSortingDropdown();
    }
    this.isAttendanceDataLoading = this.isLoaderVisible
      ? true
      : this.isAttendanceDataLoading;
  }

  emptySearchFields() {
    const tempSearch = JSON.parse(JSON.stringify(this.search));
    for (const key in tempSearch) {
      tempSearch[key] = '';
    }
    attendanceStore.mutateAttendanceSearch(tempSearch);
  }

  attandanceData(searcAndSortData: any) {
    this.searchField =
      searcAndSortData &&
      searcAndSortData.searchObj &&
      searcAndSortData.searchObj.searchField;
    this.searchValue =
      searcAndSortData &&
      searcAndSortData.searchObj &&
      searcAndSortData.searchObj.search;
    this.sortField =
      searcAndSortData &&
      searcAndSortData.sortObj &&
      searcAndSortData.sortObj.sortField;
    this.sortDir =
      searcAndSortData &&
      searcAndSortData.sortObj &&
      searcAndSortData.sortObj.sortDir;
    this.currentSortOrder = this.sortDir == 'asc'
      ? 'asc'
      : 'desc';
    this.currentSortedColumn = this.sortField
      ? this.sortField
      : 'firstName';
    this.weekDay = '';
    this.weekSort = '';
    if (this.currentSortedColumn == 'firstName') {
      this.firstNameSorted = true;
      this.lastNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.weekSort = '';
    }
    else if (this.currentSortedColumn == 'lastName') {
      this.lastNameSorted = true;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.weekSort = '';
    }
    else if (this.currentSortedColumn == 'grade') {
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.gradeSorted = true;
      this.transportationSorted = false;
      this.weekSort = '';
    }
    else if (
      (this.currentSortedColumn =
        this.tabSelected === 'Check-in'
          ? 'arrivalPlan'
          : 'dismissalPlan')
    ) {
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = true;
      this.weekSort = '';
    }
    this.emptySearchFields();
    this.getHeadersFromStorage();
    this.prepareScholarAttendanceData();
    this.closeSortingDropdown();
  }

  getSortingAndSearchingDataFromStorage() {
    let checnInSortingDatas: any = {};
    let checkOutSortingDatas: any = {};
    let checkInWeekSortingDatas: any = {};
    let checnInSearchingDatas: any = {};
    let checkOutSearchingDatas: any = {};
    let checnInWeekSearchingDatas: any = {};

    let programId: any = APP_UTILITIES.getCookie('programId');
    programId = JSON.parse(programId);
    let siteId: any = APP_UTILITIES.getCookie('siteId');
    siteId = JSON.parse(siteId);

    if (this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day') {
      /* istanbul ignore else */
      if (localStorage.getItem('CheckinSortingData')) {
        checnInSortingDatas = localStorage.getItem('CheckinSortingData');
        if (checnInSortingDatas != '') {
          checnInSortingDatas = JSON.parse(checnInSortingDatas);
          /* istanbul ignore else */
          if (checnInSortingDatas.hasOwnProperty([programId])) {
            /* istanbul ignore else */
            if (checnInSortingDatas[programId].hasOwnProperty([siteId])) {
              this.currentSortedColumn =
                checnInSortingDatas[programId][siteId].sortField;
              this.currentSortOrder =
                checnInSortingDatas[programId][siteId].sortDirection;
              this.weekSort =
                checnInSortingDatas[programId][siteId].dayItem &&
                checnInSortingDatas[programId][siteId].dayItem.day
                  ? checnInSortingDatas[programId][siteId].dayItem.day
                  : '';
            }
          }
        }
      }
      /* istanbul ignore else */
      if (
        !checnInSortingDatas.hasOwnProperty([programId]) ||
        !checnInSortingDatas[programId].hasOwnProperty([siteId])
      ) {
        this.currentSortedColumn = 'firstName';
        this.currentSortOrder = 'asc';
        this.weekSort = '';
      }

      /* ****** Fetching Check-In Day Searching Data ******* */

      /* istanbul ignore else */
      if (localStorage.getItem('CheckinSearchingData')) {
        checnInSearchingDatas = localStorage.getItem('CheckinSearchingData');
        /* istanbul ignore else */
        if (checnInSearchingDatas != '') {
          checnInSearchingDatas = JSON.parse(checnInSearchingDatas);
          /* istanbul ignore else */
          if (checnInSearchingDatas.hasOwnProperty([programId])) {
            /* istanbul ignore else */
            if (checnInSearchingDatas[programId].hasOwnProperty([siteId])) {
              if (
                checnInSearchingDatas[programId][siteId].searchField ==
                'Transportation'
              ) {
                this.searchField =
                  this.tabSelected == 'Check-in'
                    ? 'ArrivalPlan'
                    : 'Dismissalplan';
                this.searchValue = checnInSearchingDatas[programId][siteId]
                  .searchValue
                  ? checnInSearchingDatas[programId][siteId].searchValue
                  : '';
                this.currentSearchingDay = '';
              }
              else if (
                checnInSearchingDatas[programId][siteId].searchField ==
                'Attendance'
              ) {
                this.currentSearchingDay =
                  checnInSearchingDatas[programId][siteId].dayItem &&
                  checnInSearchingDatas[programId][siteId].dayItem.day
                    ? checnInSearchingDatas[programId][siteId].dayItem.day
                    : '';
                this.searchField = checnInSearchingDatas[programId][siteId]
                  .searchField
                  ? checnInSearchingDatas[programId][siteId].searchField
                  : '';
                this.searchValue = checnInSearchingDatas[programId][siteId]
                  .searchValue
                  ? checnInSearchingDatas[programId][siteId].searchValue
                  : '';
              }
              else {
                this.searchField = checnInSearchingDatas[programId][siteId]
                  .searchField
                  ? checnInSearchingDatas[programId][siteId].searchField
                  : '';
                this.searchValue = checnInSearchingDatas[programId][siteId]
                  .searchValue
                  ? checnInSearchingDatas[programId][siteId].searchValue
                  : '';
              }
            }
          }
        }
      }
      /* istanbul ignore else */
      if (
        !checnInSearchingDatas.hasOwnProperty([programId]) ||
        !checnInSearchingDatas[programId].hasOwnProperty([siteId])
      ) {
        this.searchField = '';
        this.searchValue = '';
        this.currentSearchingDay = '';
      }
    }
    else if (this.tabSelected == 'Check-out') {
      /* ****** Fetching Check-Out Day Sorting Data ******* */

      /* istanbul ignore else */
      if (localStorage.getItem('CheckOutSortingData')) {
        checkOutSortingDatas = localStorage.getItem('CheckOutSortingData');
        if (checkOutSortingDatas != '') {
          checkOutSortingDatas = JSON.parse(checkOutSortingDatas);
          if (checkOutSortingDatas.hasOwnProperty([programId])) {
            if (checkOutSortingDatas[programId].hasOwnProperty([siteId])) {
              this.currentSortedColumn =
                checkOutSortingDatas[programId][siteId].sortField;
              this.currentSortOrder =
                checkOutSortingDatas[programId][siteId].sortDirection;
              this.weekSort =
                checkOutSortingDatas[programId][siteId].dayItem &&
                checkOutSortingDatas[programId][siteId].dayItem.day
                  ? checkOutSortingDatas[programId][siteId].dayItem.day
                  : '';
              //this.weekDay = this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week' && dayItem && dayItem.day ? dayItem.day : ''
            }
          }
        }
      }
      /* istanbul ignore else */
      if (
        !checkOutSortingDatas.hasOwnProperty([programId]) ||
        !checkOutSortingDatas[programId].hasOwnProperty([siteId])
      ) {
        this.currentSortedColumn = 'firstName';
        this.currentSortOrder = 'asc';
        this.weekSort = '';
      }

      /* ****** Fetching Check-Out Day Searching Data ******* */

      /* istanbul ignore else */
      if (localStorage.getItem('CheckOutSearchingData')) {
        checkOutSearchingDatas = localStorage.getItem('CheckOutSearchingData');
        /* istanbul ignore else */
        if (checkOutSearchingDatas != '') {
          checkOutSearchingDatas = JSON.parse(checkOutSearchingDatas);
          /* istanbul ignore else */
          if (checkOutSearchingDatas.hasOwnProperty([programId])) {
            /* istanbul ignore else */
            if (checkOutSearchingDatas[programId].hasOwnProperty([siteId])) {
              if (
                checkOutSearchingDatas[programId][siteId].searchField ==
                'Transportation'
              ) {
                this.searchField = 'Dismissalplan';
                this.searchValue = checkOutSearchingDatas[programId][siteId]
                  .searchValue
                  ? checkOutSearchingDatas[programId][siteId].searchValue
                  : '';
                this.currentSearchingDay = '';
              }
              else if (
                checkOutSearchingDatas[programId][siteId].searchField ==
                'Attendance'
              ) {
                this.currentSearchingDay =
                  checkOutSearchingDatas[programId][siteId].dayItem &&
                  checkOutSearchingDatas[programId][siteId].dayItem.day
                    ? checkOutSearchingDatas[programId][siteId].dayItem.day
                    : '';
                this.searchField = checkOutSearchingDatas[programId][siteId]
                  .searchField
                  ? checkOutSearchingDatas[programId][siteId].searchField
                  : '';
                this.searchValue = checkOutSearchingDatas[programId][siteId]
                  .searchValue
                  ? checkOutSearchingDatas[programId][siteId].searchValue
                  : '';
              }
              else {
                this.searchField = checkOutSearchingDatas[programId][siteId]
                  .searchField
                  ? checkOutSearchingDatas[programId][siteId].searchField
                  : '';
                this.searchValue = checkOutSearchingDatas[programId][siteId]
                  .searchValue
                  ? checkOutSearchingDatas[programId][siteId].searchValue
                  : '';
              }
              this.isExactMatch =
                checkOutSearchingDatas[programId][siteId].isExactMatch;
              attendanceStore.mutateAttendancePayload({
                ...attendanceStore.attendanceAPIPayload,
                isExactMatch:
                  checkOutSearchingDatas[programId][siteId].isExactMatch,
              });
            }
          }
        }
      }

      /* istanbul ignore else */
      if (
        !checkOutSearchingDatas.hasOwnProperty([programId]) ||
        !checkOutSearchingDatas[programId].hasOwnProperty([siteId])
      ) {
        this.searchField = '';
        this.searchValue = '';
        this.currentSearchingDay = '';
      }
    }
    else if (
      this.tabSelected == 'Check-in' &&
      this.tabSelectedValue == 'Week'
    ) {
      /* check-in Week Sorting Dat */

      /* istanbul ignore else */
      if (localStorage.getItem('CheckinWeekSortingData')) {
        checkInWeekSortingDatas = localStorage.getItem(
          'CheckinWeekSortingData'
        );
        /* istanbul ignore else */
        if (checkInWeekSortingDatas != '') {
          checkInWeekSortingDatas = JSON.parse(checkInWeekSortingDatas);
          /* istanbul ignore else */
          if (checkInWeekSortingDatas.hasOwnProperty([programId])) {
            /* istanbul ignore else */
            if (checkInWeekSortingDatas[programId].hasOwnProperty([siteId])) {
              this.currentSortedColumn =
                checkInWeekSortingDatas[programId][siteId].sortField;
              this.currentSortOrder =
                checkInWeekSortingDatas[programId][siteId].sortDirection;
              this.weekSort =
                checkInWeekSortingDatas[programId][siteId].dayItem &&
                checkInWeekSortingDatas[programId][siteId].dayItem.day
                  ? checkInWeekSortingDatas[programId][siteId].dayItem.day
                  : '';
              this.weekDay =
                this.tabSelected == 'Check-in' &&
                this.tabSelectedValue == 'Week' &&
                checkInWeekSortingDatas[programId][siteId].dayItem &&
                checkInWeekSortingDatas[programId][siteId].dayItem.day
                  ? checkInWeekSortingDatas[programId][siteId].dayItem.day
                  : '';
            }
          }
        }
      }
      /* istanbul ignore else */
      if (
        !checkInWeekSortingDatas.hasOwnProperty([programId]) ||
        !checkInWeekSortingDatas[programId].hasOwnProperty([siteId])
      ) {
        this.currentSortedColumn = 'firstName';
        this.currentSortOrder = 'asc';
        this.weekSort = '';
        this.weekDay = '';
      }

      /* ****** Fetching Check-IN Week Searching Data ******* */

      /* istanbul ignore else */
      if (localStorage.getItem('CheckinWeekSearchingData')) {
        checnInWeekSearchingDatas = localStorage.getItem(
          'CheckinWeekSearchingData'
        );
        /* istanbul ignore else */
        if (checnInWeekSearchingDatas != '') {
          checnInWeekSearchingDatas = JSON.parse(checnInWeekSearchingDatas);
          /* istanbul ignore else */
          if (checnInWeekSearchingDatas.hasOwnProperty([programId])) {
            /* istanbul ignore else */
            if (checnInWeekSearchingDatas[programId].hasOwnProperty([siteId])) {
              if (
                checnInWeekSearchingDatas[programId][siteId].searchField ==
                'Transportation'
              ) {
                this.searchField =
                  this.tabSelected == 'Check-in'
                    ? 'ArrivalPlan'
                    : 'Dismissalplan';
                this.searchValue = checnInWeekSearchingDatas[programId][siteId]
                  .searchValue
                  ? checnInWeekSearchingDatas[programId][siteId].searchValue
                  : '';
                this.currentSearchingDay = '';
              }
              else if (
                checnInWeekSearchingDatas[programId][siteId].searchField ==
                'Attendance'
              ) {
                this.weekDay =
                  checnInWeekSearchingDatas[programId][siteId].dayItem &&
                  checnInWeekSearchingDatas[programId][siteId].dayItem.day
                    ? checnInWeekSearchingDatas[programId][siteId].dayItem.day
                    : '';
                this.currentSearchingDay = this.weekDay;
                this.searchField = checnInWeekSearchingDatas[programId][siteId]
                  .searchField
                  ? checnInWeekSearchingDatas[programId][siteId].searchField
                  : '';
                this.searchValue = checnInWeekSearchingDatas[programId][siteId]
                  .searchValue
                  ? checnInWeekSearchingDatas[programId][siteId].searchValue
                  : '';
              }
              else {
                this.searchField = checnInWeekSearchingDatas[programId][siteId]
                  .searchField
                  ? checnInWeekSearchingDatas[programId][siteId].searchField
                  : '';
                this.searchValue = checnInWeekSearchingDatas[programId][siteId]
                  .searchValue
                  ? checnInWeekSearchingDatas[programId][siteId].searchValue
                  : '';
              }
              this.isExactMatch =
                checnInWeekSearchingDatas[programId][siteId].isExactMatch;
              attendanceStore.mutateAttendancePayload({
                ...attendanceStore.attendanceAPIPayload,
                isExactMatch:
                  checnInWeekSearchingDatas[programId][siteId].isExactMatch,
              });
            }
          }
        }
      }

      /* istanbul ignore else */
      if (
        !checnInWeekSearchingDatas.hasOwnProperty([programId]) ||
        !checnInWeekSearchingDatas[programId].hasOwnProperty([siteId])
      ) {
        this.searchField = '';
        this.searchValue = '';
        this.currentSearchingDay = '';
      }
    }

    if (this.currentSortedColumn == 'firstName') {
      this.firstNameSorted = true;
      this.lastNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.weekSort = '';
    }
    else if (this.currentSortedColumn == 'lastName') {
      this.lastNameSorted = true;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
      this.weekSort = '';
    }
    else if (this.currentSortedColumn === 'grade') {
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.gradeSorted = true;
      this.transportationSorted = false;
      this.weekSort = '';
    }
    else if (this.currentSortedColumn == 'transportation') {
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = true;
      this.weekSort = '';
      this.currentSortedColumn =
        this.tabSelected === 'Check-in'
          ? 'arrivalPlan'
          : 'dismissalPlan';
    }
    else if (this.currentSortedColumn == 'Attendance') {
      this.lastNameSorted = false;
      this.firstNameSorted = false;
      this.gradeSorted = false;
      this.transportationSorted = false;
    }
  }

  tabSelectedValueToAttendanceViewMode(
    tabSelectedValue: string
  ): AttendanceViewMode {
    return tabSelectedValue === 'Day'
      ? 'daily'
      : 'weekly';
  }

  attendanceViewModeToTabSelectedValue(viewMode: AttendanceViewMode): string {
    return viewMode === 'daily'
      ? 'Day'
      : 'Week';
  }

  closeGroupsFlyout(): void {
    this.showGroupsFlyout = false;
  }

  closeAttendanceSettings(): void {
    this.showAttendanceSettings = false;
  }

  cancelAttendanceSettings(): void {
    this.showAttendanceSettings = false;
  }

  setAttendanceSettings({ viewMode }: AttendanceSettingsData): void {
    this.handleListTypeSelection(
      this.attendanceViewModeToTabSelectedValue(viewMode)
    );
  }

  saveAttendanceSettings(settings: AttendanceSettingsData): void {
    this.setAttendanceSettings(settings);

    const programId = this.userDetails.programId || APP_UTILITIES.getCookie('programId');
    const siteId = this.userDetails.siteId || APP_UTILITIES.getCookie('siteId');

    const attendanceSettingsMapping = {
      ...APP_UTILITIES.getLocalStoredAttendanceSettingsMapping() || {},
      [`${programId}_${siteId}`]: settings
    }  as Record<string, AttendanceSettingsData>;

    APP_UTILITIES.setLocalStoredAttendanceSettingsMapping(attendanceSettingsMapping);

    this.showAttendanceSettings = false;
  }

  handleSearchClean() {
    this.searchField = '';
    this.searchValue = '';
    this.prepareScholarAttendanceData();
  }

  handleSearch(search: string) {
    this.searchField = 'Name';
    this.searchValue = search;
    this.prepareScholarAttendanceData(false);
  }

  handleRowsPerPageChange(rows: number) {
    const currentTab: string = this.tabSelected || this.tabs[0];
    this.rowsPerActivePage[currentTab].desktop = rows;
    localStorage.setItem(APP_CONST.LOCAL_STORAGE_KEYS.attendance.rowsPerPage, JSON.stringify(this.rowsPerActivePage));
    this.prepareScholarAttendanceData(false);
  }

  get rowsPerPage() {
    const currentTab: string = this.tabSelected || this.tabs[0];
    return this.rowsPerActivePage[currentTab][
      this.mobileView
        ? 'mobile'
        : 'desktop'
    ];
  }
}
